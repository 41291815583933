import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.snow.css';
import { jwtDecode } from "jwt-decode";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DeleteAddressIcon, EditIcon } from '../assets/icons/hiking';
import { Skeleton } from '@mui/material';
import { SavedIcon, CheckIcon, SaveIcon, NoDataIcon } from '../assets/icons/hiking';

function MyAddress() {
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function lnAddressLookup() {
            try {
                const customer_number = localStorage.getItem('business_partner_id');
                const eid = localStorage.getItem('default');

                const payload = {
                    eid: eid,
                    endpoint: 'v1/ln/address/lookup',
                    business_partner_id: customer_number,
                };

                const response = await fetch('/admin/postData.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const data = await response.json();

                if (data.success === 1 && data.response && data.response.data) {
                    const fetchedAddressData = data.response.data;

                    const newAddress = {
                        id: String(fetchedAddressData.addressCode || 'address1'),
                        name: fetchedAddressData.name?.[0] || '',
                        phone: fetchedAddressData.telephone || '',
                        street: fetchedAddressData.streetName
                            ? `${fetchedAddressData.streetName} ${fetchedAddressData.houseNumber || ''}`.trim()
                            : '',
                        city: fetchedAddressData.cityDescription || '',
                        country: fetchedAddressData.countryDescription || '',
                    };

                    setAddresses([newAddress]);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        lnAddressLookup();
    }, []);




    return (
        <div className="p-6">
            <div className="flex items-center justify-between mb-6">
                <h1 className="text-2xl font-bold">My Address Info</h1>

                <Button
                    variant="outlined"
                    sx={{
                        padding: '12px',
                        borderColor: 'black',
                        color: 'black',
                        '&:hover': {
                            borderColor: 'gray',
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                >
                    <AddIcon />
                    Create a New Address
                </Button>
            </div>
            {loading ? (
                Array.from(new Array(3)).map((_, index) => (
                    <div className="bg-gray-100 rounded-md p-4 mb-4" key={index}>
                        <Skeleton variant="text" width="40%" />
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="80%" />
                    </div>
                ))
            ) : addresses.length === 0 ? (
                <div className="flex flex-col w-full items-center pt-14">
                    <div className="flex flex-col items-center">
                        <span className="text-xl font-semibold">No Data</span>
                    </div>
                    <NoDataIcon />
                </div>
            ) : (
                // Otherwise, map addresses
                addresses.map((address, index) => (
                    <div className="bg-gray-100 rounded-md p-4 mb-4" key={address.id}>
                        <div className="flex items-center justify-between">
                            <span className="bg-gray-200 text-gray-800 text-sm px-2 py-1 rounded-md uppercase tracking-wide">
                                Address {index + 1}
                            </span>

                            <div className="space-x-4 flex">
                                <button className="text-red-600 text-sm flex items-center gap-2">
                                    <EditIcon />
                                    Edit
                                </button>
                                <button className="text-red-600 text-sm flex items-center gap-2">
                                    <DeleteAddressIcon />
                                    Delete
                                </button>
                            </div>
                        </div>

                        <h2 className="text-lg font-bold mt-2">{address.name}</h2>
                        <p className="text-sm">{address.phone}</p>
                        <p className="text-sm">{address.street}</p>
                        <p className="text-sm">{address.city}</p>
                        <p className="text-sm">{address.country}</p>
                    </div>
                ))
            )}
        </div>
    );
}


export default MyAddress;