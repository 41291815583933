import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { FullViewIcon } from '../assets/icons/hiking';
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';

export default function Sidebar({ setSelectedCatalog, selectedCategories, setSelectedCategories }) {
    const [isExpanded, setIsExpanded] = useState(true);
    const [taxonomy, setTaxonomy] = useState([]);
    const [catalogs, setCatalogs] = useState([]);
    const [checkedMap, setCheckedMap] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "side_bar";
        const id = localStorage.getItem("nonce");

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }
        const url = protocolpluspath() +
            "admin/getData.php?eid=" + eid + "&type=" + type + "&id=" + id;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success && data.data && data.data.response) {
                    const rawTax = data.data.response.taxonomy || [];
                    const transformed = rawTax.map(node => transformTaxonomy(node));
                    setTaxonomy(transformed);

                    const rawCatalogs = data.data.response.catalogs || [];
                    setCatalogs(rawCatalogs);
                }
            })
            .catch(error => console.error("Fetch error:", error))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        const selectedPaths = Object.keys(checkedMap).filter(path => checkedMap[path]);

        const finalCategories = selectedPaths.map(path => {
            const segments = path.split(" > ");
            return segments[segments.length - 1];
        });

        setSelectedCategories(finalCategories);

        console.log("Selected categories:", finalCategories);
    }, [checkedMap]);


    useEffect(() => {
        const storedValue = localStorage.getItem("quick_add");
        if (storedValue !== null) {
            setChecked(storedValue === "true");
        }
    }, []);


    function transformTaxonomy(node, parentPath = "") {
        const path = parentPath
            ? parentPath + " > " + node.category_title
            : node.category_title;

        return {
            ...node,
            path,
            children: (node.children || []).map(child =>
                transformTaxonomy(child, path)
            )
        };
    }

    function getAllDescendants(node) {
        let results = [node];
        if (node.children) {
            node.children.forEach(child => {
                results = results.concat(getAllDescendants(child));
            });
        }
        return results;
    }

    function findParent(targetNode, allRoots) {
        for (const root of allRoots) {
            const maybe = findParentRecursive(targetNode, root);
            if (maybe) return maybe;
        }
        return null;
    }

    function findParentRecursive(targetNode, current) {
        if (!current.children) return null;
        for (const child of current.children) {
            if (child.path === targetNode.path) {
                return current;
            }
            const deeper = findParentRecursive(targetNode, child);
            if (deeper) return deeper;
        }
        return null;
    }

    function uncheckParents(childNode, updatedMap, allRoots) {
        const parent = findParent(childNode, allRoots);
        if (!parent) return;

        const kids = parent.children || [];
        if (kids.length > 1) {
            const allUnchecked = kids.every(kid => !updatedMap[kid.path]);
            if (allUnchecked) {
                updatedMap[parent.path] = false;
                uncheckParents(parent, updatedMap, allRoots);
            }
        }
    }

    const handleToggle = (node, newChecked) => {
        const updatedMap = { ...checkedMap };

        const descendants = getAllDescendants(node);
        descendants.forEach(desc => {
            updatedMap[desc.path] = newChecked;
        });

        if (!newChecked) {
            uncheckParents(node, updatedMap, taxonomy);
        }

        setCheckedMap(updatedMap);
    };

    function TaxonomyItem({ node }) {
        const checked = !!checkedMap[node.path];

        const handleCheckboxChange = () => {
            handleToggle(node, !checked);
        };

        return (
            <div className="mb-2">
                <div className="flex justify-between items-center mb-1 ml-4">
                    <span className="text-sm text-gray-700">{node.category_title}</span>
                    <Checkbox
                        className="custom-checkbox"
                        checked={checked}
                        onChange={handleCheckboxChange}
                    />
                </div>

                {node.children && node.children.length > 0 && (
                    <div className="ml-6 mt-1">
                        {node.children.map((child, idx) => (
                            <TaxonomyItem key={child.path} node={child} />
                        ))}
                    </div>
                )}
            </div>
        );
    }

    //catalog logic
    const catalogSelection = (catalogName) => {
        setSelectedCatalog(prev => {
            if (prev.includes(catalogName)) {
                return prev.filter(name => name !== catalogName);
            } else {
                return [...prev, catalogName];
            }
        });
    };

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    //quick add
    const [checked, setChecked] = useState(false);

    return (
        <div className="flex justify-start m-1 hidden sm:inline">
            {loading ? (
                <div className="flex min-h-screen">
                    <div className={`${isExpanded ? 'w-[250px]' : 'w-[25px]'}`}>
                        <ul className="pt-5">
                            <div className="px-2 font-semibold">
                                {/* Quick ADD */}
                                <div className="mb-4">
                                    <div className="flex justify-between items-center mb-2">
                                        <Skeleton variant="text" width={100} height={20} />
                                        {/* <Skeleton variant="rectangular" width={36} height={20} /> */}
                                    </div>
                                </div>
                            </div>

                            {isExpanded && <hr className="my-6 border-gray-200 border-1" />}

                            <div className="px-2 font-semibold">
                                {/* Quick ADD */}
                                <div className="mb-4">
                                    <div className="flex justify-between items-center mb-2">
                                        <Skeleton variant="text" width={100} height={20} />
                                        {/* <Skeleton variant="rectangular" width={36} height={20} /> */}
                                        <Skeleton variant="rectangular" width={36} height={20} sx={{ borderRadius: '9999px' }} />

                                    </div>
                                </div>
                            </div>

                            <hr className="my-6 border-gray-200 border-1" />

                            {isExpanded && (
                                <div className="px-2 font-semibold">
                                    {/* Simulate Catalogs */}
                                    <div className="mb-4">
                                        <Skeleton variant="text" width={80} height={20} />
                                        {[...Array(7)].map((_, idx) => (
                                            <div key={idx} className="flex justify-between items-center mb-1 ml-4">
                                                <Skeleton variant="text" width={100} height={20} />
                                                <Skeleton variant="rectangular" width={18} height={18} />
                                            </div>
                                        ))}
                                    </div>


                                    <div className="mb-4">
                                        <Skeleton variant="text" width={80} height={20} />
                                        {[...Array(2)].map((_, idx) => (
                                            <div key={idx} className="flex justify-between items-center mb-1 ml-4">
                                                <Skeleton variant="text" width={100} height={20} />
                                                <Skeleton variant="rectangular" width={18} height={18} />
                                            </div>
                                        ))}
                                    </div>


                                    <div className="mb-4">
                                        <Skeleton variant="text" width={80} height={20} />
                                        {[...Array(5)].map((_, idx) => (
                                            <div key={idx} className="flex justify-between items-center mb-1 ml-4">
                                                <Skeleton variant="text" width={100} height={20} />
                                                <Skeleton variant="rectangular" width={18} height={18} />
                                            </div>
                                        ))}
                                    </div>



                                    {/* Simulate Categories */}
                                    {/* <div>
                                        <Skeleton variant="text" width={100} height={20} />
                                        <div className="mt-2">
                                            {[...Array(5)].map((_, idx) => (
                                                <Skeleton
                                                    key={idx}
                                                    variant="rectangular"
                                                    height={24}
                                                    width="80%"
                                                    className="ml-4 my-1"
                                                />
                                            ))}
                                        </div>
                                    </div> */}

                                </div>
                            )}
                        </ul>
                    </div>
                </div>

            ) : (
                <>
                    {catalogs.length > 1 && (

                        <div className="flex min-h-screen">
                            <div className={`${isExpanded ? 'w-[250px]' : 'w-[25px]'}`}>
                                <ul className="pt-5">
                                    <li className="flex justify-between items-center text-sm font-semibold">
                                        {isExpanded && <span className="px-2">Filter By</span>}
                                        <div className="cursor-pointer m-4" onClick={toggleExpansion}>
                                        </div>
                                    </li>
                                    {isExpanded && <hr className="my-6 border-gray-200 border-1" />}

                                    <div className="px-2 font-semibold">
                                        <div className="mb-4">
                                            <div className="flex justify-between items-center mb-2">
                                                <span className="text-sm text-black">Quick ADD</span>
                                                <Switch
                                                    size="small"
                                                    checked={checked}
                                                    onChange={(e) => {
                                                        const newValue = e.target.checked;
                                                        setChecked(newValue);
                                                        localStorage.setItem("quick_add", newValue);
                                                        window.dispatchEvent(new Event("quick_add_changed"));
                                                    }}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                            color: 'black',
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSwitch-track': {
                                                            backgroundColor: '#1f2937', // Tailwind gray-800
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-6 border-gray-200 border-1" />

                                    {isExpanded && (
                                        <div className="px-2 font-semibold">
                                            {catalogs.length > 1 && (
                                                <div className="mb-4">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <span className="text-sm text-black">Catalogs</span>
                                                    </div>
                                                    {catalogs.map((catalogName, idx) => (
                                                        <div key={idx} className="flex justify-between items-center mb-1 ml-4">
                                                            <span className="text-sm text-gray-700">{catalogName}</span>
                                                            <Checkbox
                                                                className="custom-checkbox"
                                                                onChange={() => catalogSelection(catalogName)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {taxonomy.length > 0 && (
                                                <div>
                                                    <div className="flex justify-between items-center mb-2">
                                                        <span className="text-sm text-black">Categories</span>
                                                    </div>

                                                    <div className="mt-2">
                                                        {taxonomy.map((node, idx) => (
                                                            <TaxonomyItem key={node.path} node={node} />
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </div>

                    )}
                </>
            )}
        </div>
    );
}
