import React, { useState, useEffect } from 'react';



function CreateRole() {

    const [selectedFeature, setSelectedFeature] = useState('Create New Role');
    const [customColor, setCustomColor] = useState("#111111");
    const [roleName, setRoleName] = useState("");
    const [description, setDescription] = useState("");

    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [roleAccess, setRoleAccess] = useState(null);




    const handleFeatureSelect = (feature) => {
        setSelectedFeature(feature);
    };

    const rowLabels = {
        overview: "Overview",
        customers: "Customers",
        products: "Products",
        orders: "Orders",
        invoices: "Invoices",
        documents: "Documents",
        catalogs: "Catalogs",
        settings: "Settings",
    };

    const permissionTypes = ['all', 'manage', 'edit', 'create', 'suspend', 'delete'];


    const initialRowState = Object.keys(rowLabels).reduce((acc, key) => {
        acc[key] = false;
        return acc;
    }, {});

    const initialPermissions = permissionTypes.reduce((acc, type) => {
        acc[type] = { ...initialRowState };
        return acc;
    }, {});

    const [permissions, setPermissions] = useState(initialPermissions);
    const rowKeys = Object.keys(rowLabels);

    const checkAndUpdateHeaders = (updatedPermissions) => {
        const nonAllTypes = ['manage', 'edit', 'create', 'suspend', 'delete'];

        rowKeys.forEach((row) => {
            const isAllCheckedForRow = nonAllTypes.every(
                (type) => updatedPermissions[type][row]
            );
            updatedPermissions.all[row] = isAllCheckedForRow;
        });

        const isEverythingChecked = nonAllTypes.every((type) =>
            rowKeys.every((row) => updatedPermissions[type][row])
        );
        updatedPermissions.all.all = isEverythingChecked;

        return updatedPermissions;
    };

    const handleSelectHeader = (type) => {
        setPermissions((prevState) => {
            const firstRow = rowKeys[0];
            const newState = !prevState[type][firstRow];

            if (type === 'all') {
                const updatedPermissions = {};

                permissionTypes.forEach((permissionType) => {
                    updatedPermissions[permissionType] = {};
                    rowKeys.forEach((row) => {
                        updatedPermissions[permissionType][row] = newState;
                    });
                });

                return checkAndUpdateHeaders(updatedPermissions);
            }
            else {
                const updatedPermissions = {
                    ...prevState,
                    [type]: { ...prevState[type] },
                };

                rowKeys.forEach((row) => {
                    updatedPermissions[type][row] = newState;
                });

                return checkAndUpdateHeaders(updatedPermissions);
            }
        });
    };

    const handleSelectRow = (type, row) => {
        setPermissions((prevState) => {
            if (type === 'all') {
                const newState = !prevState.all[row];
                const updatedPermissions = {};

                permissionTypes.forEach((permissionType) => {
                    updatedPermissions[permissionType] = {
                        ...prevState[permissionType],
                        [row]: newState,
                    };
                });

                return checkAndUpdateHeaders(updatedPermissions);
            } else {
                const updatedPermissions = {
                    ...prevState,
                    [type]: {
                        ...prevState[type],
                        [row]: !prevState[type][row],
                    },
                };
                return checkAndUpdateHeaders(updatedPermissions);
            }
        });
    };

    function createRole() {
        const eid = localStorage.getItem('default');
        const a = localStorage.getItem("nonce");

        const { all, ...finalPermissions } = permissions;

        const payload = {
            role_name: roleName,
            description: description,
            permissions: finalPermissions,
            eid: eid,
            endpoint: "v1/role/add",
        };


        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === 1 && data.response && data.response.data) {
                    console.log(data)
                }
            })
            .catch(() => {
                alert("Something went wrong");
            });
    }


    return (
        <div className="roles-management-container">
            {/* <div className="sidebar">
                <div className="system-features">
                    <div
                        className={`system-feature ${selectedFeature === 'Roles List' ? 'active' : ''}`}
                        onClick={() => handleFeatureSelect('Roles List')}
                    >
                        Roles List
                    </div>
                    <div
                        className={`system-feature ${selectedFeature === 'Create New Role' ? 'active' : ''}`}
                        onClick={() => handleFeatureSelect('Create New Role')}
                    >
                        Create New Role
                    </div>
                </div>
            </div> */}

            <div className="role-creation-panel">
                {selectedFeature === 'Roles List' && <div>Content for Roles List</div>}
                {selectedFeature === 'Create New Role' && (
                    <div className="create-role-content">
                        <div className="header">
                            <h1 className="create-new-role-title">Create New Role</h1>
                            <button onClick={createRole} className="create-role-btn text-sm font-semibold">Create Role</button>
                        </div>
                        <hr className="divider" />
                        <form className="role-form">
                            <div className="form-row">
                                <div className="form-field">
                                    <label htmlFor="roleName">Name of Role</label>
                                    <input type="text" id="roleName" value={roleName} onChange={(e) => setRoleName(e.target.value)} placeholder="--" />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="customColor">Custom Color</label>
                                    <div className="color-picker">
                                        <input
                                            type="text"
                                            id="customColor"
                                            placeholder="Paste color code or choose"
                                            value={customColor}
                                            onChange={(e) => setCustomColor(e.target.value)}
                                        />
                                        <div className="color-input-wrapper">
                                            <input
                                                type="color"
                                                id="colorSelector"
                                                value={customColor}
                                                onChange={(e) => setCustomColor(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-field">
                                <label htmlFor="description">Description</label>
                                <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="--" />
                            </div>
                        </form>

                        <div className="form-field">
                            <label htmlFor="permissions">Permissions</label>
                            <table className="permissions-table">
                                <tbody>
                                    <tr>
                                        {['all', 'manage', 'edit', 'create', 'suspend', 'delete'].map((type) => (
                                            <td key={type} className="column-header-cell">
                                                <input
                                                    type="checkbox"
                                                    id={`header-${type}`}
                                                    checked={rowKeys.every((row) => permissions[type][row])}
                                                    onChange={() => handleSelectHeader(type)}
                                                />
                                                <label htmlFor={`header-${type}`}>
                                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                                </label>
                                            </td>
                                        ))}
                                    </tr>
                                    {rowKeys.map((row) => (
                                        <tr key={row}>
                                            {['all', 'manage', 'edit', 'create', 'suspend', 'delete'].map((type) => (
                                                <td key={`${type}-${row}`}>
                                                    <input
                                                        type="checkbox"
                                                        id={`${type}-${row}`}
                                                        checked={permissions[type][row]}
                                                        onChange={() => handleSelectRow(type, row)}
                                                    />
                                                    {type === 'all' && (
                                                        <label htmlFor={`${type}-${row}`}>{rowLabels[row]}</label>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );

}

export default CreateRole;
