import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { motion } from "framer-motion";
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNotifications } from '../context/NotificationContext';
import { Accordion, AccordionSummary, AccordionDetails, MenuItem, Select, TextField, Typography, FormControl, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CreateProduct() {

    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [sku, setSku] = useState('');
    const [images_primary, setImages_primary] = useState('');

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [categoriesData, setCategoriesData] = useState([]);
    const { showNotification } = useNotifications();

    const [utilityTags, setUtilityTags] = useState([]);
    const [priorityTags, setPriorityTags] = useState([]);
    const [selectedUtility, setSelectedUtility] = useState("");
    const [selectedPriority, setSelectedPriority] = useState("");
    const [wheelWeight, setWheelWeight] = useState("");
    const [vendor, setVendor] = useState("");
    const [moq, setMoq] = useState("");
    const [allowedIncrements, setAllowedIncrements] = useState("");

    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = "taxonomy";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setCategories(data.data.response || []);
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);


    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "tags";

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const utilities = data.data.response.filter((item) => item.type === "utility");
                    const priorities = data.data.response.filter((item) => item.type === "priority");
                    setUtilityTags(utilities);
                    setPriorityTags(priorities);
                }
            })
            .catch((error) => console.error("Error:", error));
    }, []);


    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = "tags";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data)
                }
            })
            .catch(error => console.error('Error:', error));
    }, [])

    const handleCategoryChange = (level, selectedNonce) => {
        setSelectedCategories((prev) => {
            const updated = { ...prev, [level]: selectedNonce };
            Object.keys(updated).forEach((key) => {
                if (Number(key) > level) {
                    delete updated[key];
                }
            });
            return updated;
        });
    };

    const renderSelect = (categories, level = 0) => {
        if (!categories || categories.length === 0) {
            return null;
        }

        const selectedNonce = selectedCategories[level];

        return (
            <div key={level} className="mb-4">
                <Typography variant="subtitle1">Select Level {level + 1} Category</Typography>
                <Select
                    fullWidth
                    value={selectedNonce || ""}
                    onChange={(e) => handleCategoryChange(level, e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="">
                        <em>--Select Category--</em>
                    </MenuItem>
                    {categories.map((cat) => (
                        <MenuItem key={cat.nonce} value={cat.nonce}>
                            {cat.category_title}
                        </MenuItem>
                    ))}
                </Select>
                {selectedNonce &&
                    renderSelect(
                        categories.find((cat) => cat.nonce === selectedNonce)?.children || [],
                        level + 1
                    )}
            </div>
        );
    };


    const addCategoryBlock = () => {
        setCategoriesData(prev => [
            ...prev,
            { categoryNonce: '', tags: [''], availableTags: [] }
        ]);
    };

    useEffect(() => {
        if (categoriesData.length === 0) {
            addCategoryBlock();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const eid = localStorage.getItem('default');
        const selectedNonces = Object.values(selectedCategories);

        const payload = {
            eid: eid,
            endpoint: "v1/product/add",

            title,
            slug,
            short_description: shortDescription,
            long_description: description,
            sku,
            images_primary,
            catalog_1: "Luis Catalog",
            selected_categories: selectedNonces,
            tags: {
                utility: [selectedUtility],
                primary: [selectedPriority],
            },
            custom_1: moq,
            custom_2: allowedIncrements,
            custom_3: wheelWeight,
            custom_4: vendor,
        };

        // console.log(payload);

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response.nonce) {
                    const Notificationpayload = {
                        response: true,
                        subject: "Product",
                        operation: "Created",
                        context: "Success!!",
                    };
                    showNotification(Notificationpayload);

                } else {
                    const Notificationpayload = {
                        response: false,
                        subject: "Product",
                        operation: "Creating",
                        context: "Error!",
                    };
                    showNotification(Notificationpayload);
                }
            });
    };


    const handleWheelWeightChange = (event) => {
        setWheelWeight(event.target.value);
    }

    const handleVendor = (event) => {
        setVendor(event.target.value);
    }

    const handleMoq = (event) => {
        setMoq(event.target.value);
    }

    const handleAllowedIncrements = (event) => {
        setAllowedIncrements(event.target.value);
    }

    return (
        <div className="px-10">

            <div className="flex justify-between items-center py-6 pt-10">

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex justify-center w-full min-h-screen pt-5"
                >

                    <div className="container bg-white shadow-2xl mx-auto rounded-lg p-6 custom-width">

                        <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-gray-100 rounded-lg shadow-md">
                            <div>
                                <TextField
                                    fullWidth
                                    label="Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Slug"
                                    value={slug}
                                    onChange={(e) => setSlug(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Short Description"
                                    value={shortDescription}
                                    multiline
                                    rows={2}
                                    onChange={(e) => setShortDescription(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    value={description}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="SKU"
                                    value={sku}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setSku(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="images_primary"
                                    value={images_primary}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setImages_primary(e.target.value)}
                                />
                            </div>

                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    Utility Tags
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <Select
                                        labelId="utility-select-label"
                                        value={selectedUtility}
                                        onChange={(e) => setSelectedUtility(e.target.value)}
                                    >
                                        {utilityTags.map((tag) => (
                                            <MenuItem key={tag.nonce} value={tag.nonce}>
                                                {tag.long_description || "No Description"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
                                    Product Tags
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <Select
                                        labelId="priority-select-label"
                                        value={selectedPriority}
                                        onChange={(e) => setSelectedPriority(e.target.value)}
                                    >
                                        {priorityTags.map((tag) => (
                                            <MenuItem key={tag.nonce} value={tag.nonce}>
                                                {tag.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
                                    MOQ (Minimum Order Quantity)
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <Select value={moq} onChange={handleMoq}>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                    </Select>
                                </FormControl>


                                <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
                                    Allowed Increments
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <Select value={allowedIncrements} onChange={handleAllowedIncrements}>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                    </Select>
                                </FormControl>

                                <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
                                    wheel Weight
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <Select value={wheelWeight} onChange={handleWheelWeightChange}>
                                        <MenuItem value={0}>Non Weight Item</MenuItem>
                                        <MenuItem value={1}>Wheel Weight</MenuItem>
                                        <MenuItem value={2}>Lead Wheel Weight</MenuItem>
                                    </Select>
                                </FormControl>


                                <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
                                    Vendor
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <Select value={vendor} onChange={handleVendor}>
                                        <MenuItem value={1}>Wegman</MenuItem>
                                        <MenuItem value={2}>Plombco</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>


                            <hr className="border-gray-300" />

                            <Typography variant="h5" className="font-bold">
                                Catalog Taxonomy
                            </Typography>

                            <Accordion className="bg-white">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className="font-semibold">Select Taxonomy</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {renderSelect(categories)}
                                </AccordionDetails>
                            </Accordion>


                            <hr className="border-gray-300" />

                            <Button type="submit" variant="contained" color="success" fullWidth>
                                Save Product
                            </Button>
                        </form>

                    </div>
                </motion.div>


            </div>
        </div>
    );

}


export default CreateProduct;


