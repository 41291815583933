// SearchContext.js
import React, { useState, createContext, useEffect } from 'react';

export const SearchContext = createContext();

const decodeHtml = (html) => {
    if (!html) return '';
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

export function SearchProvider({ children }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [products, setProducts] = useState([]);
    const [searchSuggestions, setSearchSuggestions] = useState([]);

    useEffect(() => {
        if (inputValue.trim() !== '' && products.length > 0) {
            const filtered = products.filter(product => {
                const productTitle = product.product && decodeHtml(product.product.title) || '';
                const productSKU = product.identifiers && product.identifiers.sku || '';
                const itemNumber = product.ln && product.ln.item_number || '';
                const searchLower = inputValue.toLowerCase();

                return (
                    productTitle.toLowerCase().includes(searchLower) ||
                    productSKU.toLowerCase().includes(searchLower) ||
                    itemNumber.toLowerCase().includes(searchLower)
                );
            }).slice(0, 5);

            setSearchSuggestions(filtered.map(product => {
                const productTitle = product.product && decodeHtml(product.product.title) || '';
                const productSKU = product.identifiers && product.identifiers.sku || '';
                const itemNumber = product.ln && product.ln.item_number || '';

                return {
                    value: itemNumber || productTitle,
                    searchValue: itemNumber || '',
                    displayText: productTitle,
                    label: (
                        <div>
                            <div>{productTitle}</div>
                            <div className="text-xs text-gray-500">
                                {productSKU && `SKU: ${productSKU}`}
                                {itemNumber && ` | Item: ${itemNumber}`}
                            </div>
                        </div>
                    ),
                    product: product
                };
            }));
        } else {
            setSearchSuggestions([]);
        }
    }, [inputValue, products]);

    return (
        <SearchContext.Provider value={{
            searchTerm,
            setSearchTerm,
            inputValue,
            setInputValue,
            products,
            setProducts,
            searchSuggestions
        }}>
            {children}
        </SearchContext.Provider>
    );
}