import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.snow.css';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function MyAccount() {
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        const token = sessionStorage.getItem("auth_token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                const flattened = flattenJwtData(decoded);
                setUserData(flattened);
            } catch (error) {
                console.error("Invalid JWT:", error);
            }
        }
    }, []);

    function flattenJwtData(decoded) {
        const { catalog_enum, ...rest } = decoded;

        const flattened = {
            ...rest,
            eid: localStorage.getItem("default"),
            endpoint: "v1/customer/update",
        };

        if (Array.isArray(catalog_enum)) {
            catalog_enum.forEach((catalogObj) => {
                // Example of a single catalogObj: { "catalog_1": [ "Luis Catalog", "*** Remove Catalog ***" ] }
                const [catalogKey, catalogValues] = Object.entries(catalogObj)[0];
                // Filter out '*** Remove Catalog ***'
                const filteredValues = catalogValues.filter(
                    (val) => val !== "*** Remove Catalog ***"
                );

                flattened[catalogKey] = filteredValues[0] || "";
            });
        }

        return flattened;
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    async function handleSaveChanges() {
        try {
            const token = sessionStorage.getItem("auth_token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.patch(
                "/admin/postData.php",
                userData,
                config
            );

            if (response.data.success && response.data.response === "OK") {
                navigate('/logout')
            }
        } catch (error) {
            alert("Error updating profile :(");
        }
    }
    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">My Details</h2>

                <button
                    className="bg-[#EA0A25] text-white px-4 py-2 rounded-md font-medium"
                    onClick={handleSaveChanges}
                >
                    SAVE CHANGES
                </button>
            </div>

            <div className="border-b pb-4 mb-4">
                <div className="flex items-center justify-between border-b pb-2 mb-4">
                    <h3 className="font-semibold">Personal Information</h3>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4 items-center">
                    <p className="text-gray-600 text-sm col-span-1">
                        This section contains your personal details, including your name, contact number, and email address. If
                        any of the information is incorrect or outdated, please update it accordingly or request changes where necessary.
                    </p>
                    <div>
                        <label className="block text-sm font-medium">First Name</label>
                        <input type="text" className="w-3/4 bg-gray-100 border border-gray-400 rounded px-3 py-2" name="first_name" onChange={handleInputChange} value={userData?.first_name || ''} />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4 items-center">
                    <div className="col-span-1"></div>
                    <div>
                        <label className="block text-sm font-medium">Last Name</label>
                        <input type="text" className="w-3/4 bg-gray-100 border border-gray-400 rounded px-3 py-2" name="last_name" onChange={handleInputChange} value={userData.last_name || ''} />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4 items-center">
                    <div className="col-span-1"></div>
                    <div>
                        <label className="block text-sm font-medium">Mobile No</label>
                        <input className="w-3/4 bg-gray-100 border border-gray-400 rounded px-3 py-2" name="phone" onChange={handleInputChange} value={userData.phone} />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 items-center">
                    <div className="col-span-1"></div>
                    <div>
                        <label className="block text-sm font-medium">Email Address</label>
                        <input type="email" className="w-3/4 bg-gray-100 border border-gray-400 rounded px-3 py-2" name="email" onChange={handleInputChange} value={userData.email} />
                        <p className="text-blue-500 text-xs cursor-pointer mt-2">Request to change Email</p>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-between">
                <h3 className="font-semibold">Account Information</h3>
            </div>
            <div className="border-b pb-4 mb-4">

                <div className="grid grid-cols-2 gap-4 items-center">
                    <p className="text-gray-600 text-sm col-span-1">
                        Your account information includes your username and password, which are essential for accessing your account securely.
                        Your username is unique to your profile and helps identify you within the system. For security reasons, your password is hidden.
                        If you need to update your login credentials, we recommend using a strong and unique password to enhance account security
                    </p>
                    <div className="space-y-4">
                        <div className="flex justify-between">
                            <p className="text-sm font-medium">Account Created</p>
                            <p className="text-sm font-medium">
                                {typeof userData?.created_date === 'string'
                                    ? userData.created_date.split(' ')[0]
                                    : ''}
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-sm font-medium">Username</p>
                            <p className="text-gray-700">{userData.username}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-sm font-medium">Password</p>
                            <p className="text-gray-700">{userData.password}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default MyAccount;