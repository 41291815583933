import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function RoleManagement() {

    const [selectedFeature, setSelectedFeature] = useState('Roles List');
    const [priceLookUpLoading, setPriceLookUpLoading] = useState(false);

    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [roleAccess, setRoleAccess] = useState(null);

    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        const eid = localStorage.getItem("default");
        const id = localStorage.getItem("nonce");
        const type = "roles";

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }
        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&id=" + id;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setRoles(data.data.response)
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const roleClick = (role) => {
        setSelectedRole(role);
        fetchRoleAccess(role.nonce);
    };



    const fetchRoleAccess = (theAPI) => {

        const eid = localStorage.getItem("default");
        const nonce = localStorage.getItem("nonce");
        const type = "access";

        setPriceLookUpLoading(true);

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }
        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&theAPI=" + theAPI + "&nonce=" + nonce;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setRoleAccess(data.data.response);
                    processPermissions(data.data.response);
                }
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    }

    const processPermissions = (accessList) => {
        let newPermissions = JSON.parse(JSON.stringify(initialPermissions));
        accessList.forEach((item) => {
            const { type, subtype, suspended_date } = item.access;
            if (!newPermissions[type]) {
                newPermissions[type] = {};
            }
            newPermissions[type][subtype] = (suspended_date === null);
        });

        setPermissions(newPermissions);
        setIsDirty(false);

    };


    const rowLabels = {
        overview: "Overview",
        customers: "Customers",
        products: "Products",
        orders: "Orders",
        invoices: "Invoices",
        documents: "Documents",
        catalogs: "Catalogs",
        settings: "Settings",
    };

    const permissionTypes = ['all', 'manage', 'edit', 'create', 'suspend', 'delete'];

    const initialRowState = Object.keys(rowLabels).reduce((acc, key) => {
        acc[key] = false;
        return acc;
    }, {});

    const initialPermissions = permissionTypes.reduce((acc, type) => {
        acc[type] = { ...initialRowState };
        return acc;
    }, {});

    const [permissions, setPermissions] = useState(initialPermissions);
    const rowKeys = Object.keys(rowLabels);

    const checkAndUpdateHeaders = (updatedPermissions) => {
        const nonAllTypes = ['manage', 'edit', 'create', 'suspend', 'delete'];

        rowKeys.forEach((row) => {
            const isAllCheckedForRow = nonAllTypes.every(
                (type) => updatedPermissions[type][row]
            );
            // updatedPermissions.all[row] = isAllCheckedForRow;
        });

        const isEverythingChecked = nonAllTypes.every((type) =>
            rowKeys.every((row) => updatedPermissions[type][row])
        );
        // updatedPermissions.all.all = isEverythingChecked;

        return updatedPermissions;
    };

    const handleSelectHeader = (type) => {
        setPermissions((prevState) => {
            const firstRow = rowKeys[0];
            const newState = !prevState[type][firstRow];

            if (type === 'all') {
                const updatedPermissions = {};

                permissionTypes.forEach((permissionType) => {
                    updatedPermissions[permissionType] = {};
                    rowKeys.forEach((row) => {
                        updatedPermissions[permissionType][row] = newState;
                    });
                });

                return checkAndUpdateHeaders(updatedPermissions);
            }
            else {
                const updatedPermissions = {
                    ...prevState,
                    [type]: { ...prevState[type] },
                };

                rowKeys.forEach((row) => {
                    updatedPermissions[type][row] = newState;
                });

                return checkAndUpdateHeaders(updatedPermissions);
            }
        });
        setIsDirty(true);
    };

    const handleSelectRow = (type, row) => {
        setPermissions((prevState) => {
            if (type === 'all') {
                const newState = !prevState.all[row];
                const updatedPermissions = {};

                permissionTypes.forEach((permissionType) => {
                    updatedPermissions[permissionType] = {
                        ...prevState[permissionType],
                        [row]: newState,
                    };
                });

                return checkAndUpdateHeaders(updatedPermissions);
            } else {
                const updatedPermissions = {
                    ...prevState,
                    [type]: {
                        ...prevState[type],
                        [row]: !prevState[type][row],
                    },
                };
                return checkAndUpdateHeaders(updatedPermissions);
            }
        });
        setIsDirty(true);
    };


    const handleSavePermissions = () => {
        if (!selectedRole) return;

        const eid = localStorage.getItem("default");

        const payload = {
            eid: eid,
            nonce: selectedRole.nonce,
            permissions: permissions,
            endpoint: "v1/access/change"
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Bulk update result:', data);

                setIsDirty(false);
            })
            .catch((error) => {
                console.error('Error during bulk permissions update:', error);
            });
    };

    return (
        <div className="roles-management-container">
            <div className="role-creation-panel">

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={priceLookUpLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {selectedFeature === "Roles List" && (
                    <div>
                        <h2 className="text-lg font-semibold mb-4">Roles List</h2>
                        <div className="flex flex-wrap gap-2">
                            {roles.map((role) => (
                                <button
                                    key={role.nonce}
                                    onClick={() => roleClick(role)}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                                >
                                    {role.title}
                                </button>
                            ))}
                        </div>

                        {selectedRole && roleAccess && roleAccess.length > 0 && (
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold">Access for {selectedRole.title}</h3>
                                <table className="permissions-table">
                                    <tbody>
                                        <tr >
                                            {["all", "manage", "edit", "create", "suspend", "delete"].map((type) => (
                                                <td key={type} className="column-header-cell">
                                                    <input
                                                        type="checkbox"
                                                        id={`header-${type}`}
                                                        checked={rowKeys.every((row) => permissions[type]?.[row] ?? false)}
                                                        onChange={() => handleSelectHeader(type)}
                                                    />
                                                    <label htmlFor={`header-${type}`}>
                                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                                    </label>
                                                </td>
                                            ))}
                                        </tr>

                                        {rowKeys.length > 0 ? (
                                            rowKeys.map((row) => (
                                                <tr key={row}>
                                                    {["all", "manage", "edit", "create", "suspend", "delete"].map((type) => (
                                                        <td key={`${type}-${row}`} >
                                                            <input
                                                                type="checkbox"
                                                                id={`${type}-${row}`}
                                                                checked={permissions[type]?.[row] ?? false}
                                                                onChange={() => handleSelectRow(type, row)}
                                                            />
                                                            {type === "all" && (
                                                                <label htmlFor={`${type}-${row}`}>
                                                                    {rowLabels[row] || row}
                                                                </label>
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center p-4 text-gray-500">
                                                    No permissions available.
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                {isDirty && (
                                    <button
                                        onClick={handleSavePermissions}
                                        className="mt-4 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                                    >
                                        Update Permissions
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );

}


export default RoleManagement;


