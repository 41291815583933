import React, { useState } from 'react';
import { motion } from "framer-motion";


export default function Contact() {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col justify-center items-center pt-4"
            style={{ paddingTop: '150px' }}
        >

            <div className="max-w-4xl mx-auto py-8 px-4">
                {/* Simple Header */}
                <div className="mb-8 text-center">
                    <h1 className="text-2xl font-medium text-gray-800">Contact Us</h1>
                </div>

                {/* Contact Cards in a cleaner layout */}
                <div className="grid md:grid-cols-2 gap-6">
                    {/* US Contact Card */}
                    <div className="bg-white rounded border border-gray-200 overflow-hidden">
                        <div className="border-b border-gray-200 p-4">
                            <h2 className="text-lg font-medium text-gray-700">CONTACT TECH US</h2>
                        </div>
                        <div className="p-4 space-y-3">
                            <div>
                                <p className="text-sm text-gray-500 mb-1">Phone</p>
                                <p className="text-gray-800">800-123-4567</p>
                            </div>

                            <div>
                                <p className="text-sm text-gray-500 mb-1">Email</p>
                                <a href="mailto:tech-us@trc4r.com" className="text-blue-600 hover:underline">
                                    tech-us@trc4r.com
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* EMEA Contact Card */}
                    <div className="bg-white rounded border border-gray-200 overflow-hidden">
                        <div className="border-b border-gray-200 p-4">
                            <h2 className="text-lg font-medium text-gray-700">CONTACT TECH EMEA</h2>
                        </div>
                        <div className="p-4 space-y-3">
                            <div>
                                <p className="text-sm text-gray-500 mb-1">Phone</p>
                                <p className="text-gray-800">+81-123-456-70</p>
                            </div>

                            <div>
                                <p className="text-sm text-gray-500 mb-1">Email</p>
                                <a href="mailto:tech-emea@trc4r.com" className="text-blue-600 hover:underline">
                                    tech-emea@trc4r.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Simple Message Form */}
                <div className="mt-8 bg-white rounded border border-gray-200 p-6">
                    <h2 className="text-lg font-medium text-gray-800 mb-4">Send a Message</h2>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                placeholder="Your name"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                placeholder="your.email@example.com"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Message</label>
                            <textarea
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 h-24"
                                placeholder="Your message"
                            ></textarea>
                        </div>

                        <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors">
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
