import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

function Taxonomy() {

    const [categories, setCategories] = useState([
        { title: "", shortDesc: "", longDesc: "", children: [] }
    ]);


    const addSubcategory = (path) => {
        setCategories((old) => {
            const newCategories = structuredClone(old);

            let node = newCategories;
            for (let i = 0; i < path.length; i++) {
                node = node[path[i]].children;
            }
            // Push a fresh empty child
            node.push({
                title: '',
                shortDesc: '',
                longDesc: '',
                children: []
            });

            return newCategories;
        });
    };


    const updateCategory = (path, field, value) => {
        setCategories((old) => {
            const newCategories = structuredClone(old);

            // The last index in path is the "current" node
            // Everything before that helps you descend to its children
            let node = newCategories;
            for (let i = 0; i < path.length - 1; i++) {
                node = node[path[i]].children;
            }

            // Finally, update the node at the last index
            node[path[path.length - 1]][field] = value;

            return newCategories;
        });
    };

    const buildCategoryData = (category) => {
        return {
            title: category.title,
            short_description: category.shortDesc,
            long_description: category.longDesc,
            children: category.children.map(buildCategoryData),
        };
    };

    const removeCategory = (path) => {
        setCategories((old) => {
            const newCategories = structuredClone(old);

            let node = newCategories;
            for (let i = 0; i < path.length - 1; i++) {
                node = node[path[i]].children;
            }

            node.splice(path[path.length - 1], 1);
            return newCategories;
        });
    };




    const submitTaxonomy = async () => {
        const eid = localStorage.getItem('default');
        const a = localStorage.getItem("nonce");
        const payload = {
            taxonomy: categories.map(buildCategoryData),
            endpoint: "v1/taxonomy/add",
            eid: eid,
            a: a
        };
        try {
            const response = await fetch("/admin/postData.php", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                const text = await response.text();
                alert("Error: " + text);
                return;
            }
            const result = await response.json();
            console.log("Taxonomy created:", result);
            alert("Success! Check console for details.");
        } catch (err) {
            console.error(err);
            alert("Request failed.");
        }
    };

    const renderCategory = (category, path = []) => {
        return (
            <div key={path.join('-')} style={{ marginLeft: path.length * 30 }}>
                <label>
                    Title:
                    <input
                        type="text"
                        value={category.title}
                        onChange={(e) => updateCategory(path, 'title', e.target.value)}
                    />
                </label>
                <label>
                    Short Desc:
                    <input
                        type="text"
                        value={category.shortDesc}
                        onChange={(e) => updateCategory(path, 'shortDesc', e.target.value)}
                    />
                </label>
                <label>
                    Long Desc:
                    <input
                        type="text"
                        value={category.longDesc}
                        onChange={(e) => updateCategory(path, 'longDesc', e.target.value)}
                    />
                </label>
                <button
                    className="text-blue-500 hover:underline text-sm mt-2"
                    onClick={() => addSubcategory(path)}
                >
                    Add Subcategory
                </button>

                <button
                    className="text-red-500 hover:underline text-sm mt-2 ml-2"
                    onClick={() => removeCategory(path)}
                >
                    X
                </button>


                <div className="subcategories">
                    {category.children.map((subcat, i) =>
                        renderCategory(subcat, [...path, i])
                    )}
                </div>
                <hr />
            </div>
        );
    };

    return (
        <div className='px-10'>
            <h1 className='text-3xl font-bold text-gray-800 mb-4'>Create a New Taxonomy</h1>
            <div id="categories">
                {categories.map((cat, index) => renderCategory(cat, [index]))}
            </div>
            <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-200"
                onClick={submitTaxonomy}
            >
                Submit
            </button>

        </div>
    );

}


export default Taxonomy;


