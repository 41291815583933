import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import { Skeleton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Card, Space } from 'antd';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useCart } from '../context/CartContext';
import { Badge } from 'antd';
import { CheckIcon, SaveIcon, SavedIcon } from '../assets/icons/hiking';
import Tooltip from '@mui/material/Tooltip';

function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}


export default function ProductDetails() {
    const { triggerCartUpdate } = useCart();
    const [popupProductData, setPopupProductData] = useState(null);
    const [popupCount, setPopupCount] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [priceLookUpLoading, setPriceLookUpLoading] = useState(false);

    const [count, setCount] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState({});

    const currency = localStorage.getItem("currency");
    const currencySymbol = currency === "EUR" ? "€" : currency === "USD" ? "$" : "";

    const navigate = useNavigate();
    const queryParams = useQueryParams();
    const product_nonce = queryParams.get("nonce");

    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (!product_nonce) {
            setError("No SKU :(");
            return;
        }

        fetchProductData(product_nonce);
    }, [product_nonce]);

    function fetchProductData(product_nonce) {
        const eid = localStorage.getItem("default");
        const nonce = localStorage.getItem("nonce");
        const type = "product";

        const protocolpluspath = () => `${window.location.protocol}//${window.location.host}/`;

        const url = `${protocolpluspath()}admin/getData.php?eid=${eid}&type=${type}&search=&nonce=${nonce}&id=${product_nonce}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success && data.data.success) {
                    const productData = data.data.response[0];

                    if (productData) {
                        setProduct({
                            ...productData,
                            cleanDescription: productData.product.long_description.replace(/<\/?[^>]+(>|$)/g, ""),
                        });

                        const item_number = productData.ln.item_number;
                        simulatePriceFetch(item_number);
                    } else {
                        setError("Product not found");
                    }
                } else {
                    setError("Product not found");
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError("Error fetching product data");
            });
    }

    const renderTaxonomy = (categories, level = 0) => {
        return categories.map((category, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
                <p style={{ marginLeft: `${20 * level}px` }}>
                    - {category.category_title}
                </p>
                {category.tags && category.tags.map((tag, tagIndex) => (
                    <p key={tagIndex} style={{ marginLeft: `${20 * (level + 1)}px` }}>
                        | - {tag.title}
                    </p>
                ))}
                {category.children && category.children.length > 0 && renderTaxonomy(category.children, level + 1)}
            </div>
        ));
    };

    const [price, setPrice] = useState(null);

    function simulatePriceFetch(item_number) {
        const customer_number = localStorage.getItem('business_partner_id');
        const eid = localStorage.getItem('default');
        const site_location = localStorage.getItem('site_location');
        const currency = localStorage.getItem('currency')

        const payload = {
            eid: eid,
            endpoint: 'v1/get/price',
            business_partner_id: customer_number,
            item_number: item_number,
            site_location: site_location,
            currency: currency
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === 1 && data.response && data.response.data) {
                    const netAmount = data.response.data.netAmount;
                    setPrice(netAmount);
                }
                setIsLoading(false);
            })
            .catch(() => {
                alert("Something went wrong");
                setIsLoading(false);
            });

    }

    if (error) {
        return <div>{error}</div>;
    }

    if (product === null) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100">
                <div className="max-w-6xl w-full border border-gray-300 p-8 rounded-lg shadow-lg">
                    <div className="flex flex-col md:flex-row">
                        <div className="md:w-1/2 flex justify-center items-center">
                            <Skeleton variant="rectangular" width="100%" height={400} />
                        </div>
                        <div className="md:w-1/2 mt-4 md:mt-0 md:ml-8 flex flex-col justify-center space-y-6">
                            <Skeleton variant="text" width="60%" height={40} />
                            <Skeleton variant="text" width="80%" />
                            <Skeleton variant="text" width="80%" />
                            <Skeleton variant="text" width="40%" />
                            <Skeleton variant="rectangular" width={210} height={36} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //ampersand
    function decodeHtml(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    function extractTextFromJson(jsonString) {
        try {
            const decodedJsonString = decodeHtml(jsonString);
            const jsonData = JSON.parse(decodedJsonString);

            if (jsonData.blocks && Array.isArray(jsonData.blocks)) {
                return jsonData.blocks.map(block => {
                    if (block.type === "unordered-list-item") {
                        return `• ${block.text}`;
                    }
                    return block.text;
                }).join("\n\n");
            }
        } catch (error) {
            console.error("Invalid JSON input:", error);
        }
        return jsonString;
    }

    function cleanText(text) {
        if (typeof text !== "string") return text;

        let decodedText = text;
        let previousText = '';
        while (decodedText !== previousText) {
            previousText = decodedText;
            decodedText = decodeHtml(decodedText);
        }

        if (decodedText.trim().startsWith("{") && decodedText.trim().endsWith("}")) {
            decodedText = extractTextFromJson(decodedText);
        }

        decodedText = decodedText.replace(/<\/?[^>]+(>|$)/gm, '');

        return decodedText.trim();
    }

    const cleanTitle = cleanText(product.product.title);
    const cleanDescription = cleanText(product.product.long_description);


    const goBack = () => window.history.back();

    const handleClosePopup = () => {
        setModalOpen(false);
        setSelectedProduct(null);
    };


    function addToNttCart(productData) {
        const { id, nonce, title, Description, image, sku, item_number, initialCount } = productData;
        // Add 1 to the current cart quantity
        const newCount = initialCount + 1;
        const eid = localStorage.getItem("default");
        const a = localStorage.getItem("nonce");
        const payload = {
            eid: eid,
            a: a,
            endpoint: 'v1/cart/update',
            quantities: [
                {
                    name: nonce,
                    value: 1, // 1 because we're adding one to the cart.
                },
            ],
        };
        setPriceLookUpLoading(true);
        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setPriceLookUpLoading(false);
                if (data.success && data.response === "OK") {
                    // Update the product's cart value to reflect new quantity
                    // This ensures the badge will update with the correct number
                    setProduct(prevProduct => ({
                        ...prevProduct,
                        product: {
                            ...prevProduct.product,
                            cart: newCount
                        }
                    }));

                    const newProductData = {
                        id,
                        title,
                        Description,
                        image,
                        sku,
                        item_number,
                        nonce,
                        cart: newCount, // Add this property to track cart quantity
                        initialCount: newCount,
                    };
                    setPopupProductData(newProductData);
                    setSelectedProduct(newProductData);
                    setPopupCount(newCount);
                    setModalOpen(true);
                    triggerCartUpdate();
                } else {
                    alert("No price found");
                    return null;
                }
            })
            .catch(() => {
                setPriceLookUpLoading(false);
                alert("Something went wrong");
                return null;
            });
    }


    function updateNttCart(nonce, quantity) {
        const eid = localStorage.getItem("default");
        const a = localStorage.getItem("nonce");

        const payload = {
            eid: eid,
            a: a,
            endpoint: 'v1/cart/update/quantity',
            quantities: [
                {
                    name: nonce,
                    value: quantity,
                },
            ],

        };

        setPriceLookUpLoading(true);
        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setPriceLookUpLoading(false);
                if (data.success && data.response === "OK") {
                    setProduct(prevProduct => ({
                        ...prevProduct,
                        product: {
                            ...prevProduct.product,
                            cart: quantity
                        }
                    }));

                    if (selectedProduct && selectedProduct.nonce === nonce) {
                        setSelectedProduct(prev => ({
                            ...prev,
                            cart: quantity,
                            initialCount: quantity
                        }));
                    }

                    if (popupProductData && popupProductData.nonce === nonce) {
                        setPopupProductData(prev => ({
                            ...prev,
                            cart: quantity,
                            initialCount: quantity
                        }));
                    }

                    setPopupCount(quantity);
                    setModalOpen(false);
                    triggerCartUpdate();
                } else {
                    alert("Can't update Product.");
                }
            })
            .catch(() => {
                setPriceLookUpLoading(false);
                alert("Cart update cart.");
            });
    }

    function AddToCartPopup({ product, onClose, updateQuantity }) {
        const decodedTitle = decodeHtml(product.title);
        const decodedDescription = decodeHtml(product.Description);
        const popupRef = useRef(null);

        const [count, setCount] = useState(product.cart || 1);

        useEffect(() => {
            setCount(product.cart || 1);
        }, [product.cart]);

        const increment = () => setCount((prev) => prev + 1);
        const decrement = () => {
            if (count > 1) {
                setCount((prev) => prev - 1);
            }
        };

        const inputCounter = (e) => {
            const val = parseInt(e.target.value, 10);
            if (!isNaN(val) && val >= 1) {
                setCount(val);
                updateQuantity(product.nonce, val);
            }
        };

        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };


        return (
            <div
                className="fixed z-10 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
                onClick={handleOutsideClick}
            >
                <div ref={popupRef} className="bg-white p-5 rounded-lg shadow-lg w-full max-w-4xl mx-4 md:mx-6 lg:mx-8">
                    <div className="py-4 flex items-center justify-center p-4 bg-green-100">
                        <CheckIcon className="w-6 h-6 md:w-8 md:h-8" />
                        <p className="font-bold text-green-600 ml-2 text-sm md:text-base lg:text-lg">Product successfully added to your cart!</p>
                    </div>

                    <div className="flex flex-col md:flex-row px-4">
                        <img
                            src={product.image}
                            className="mb-4 md:mb-0 md:mr-4 max-w-full md:max-w-xs h-auto"
                            alt={product.title}
                        />

                        <div className="flex-1">
                            <p className="text-lg font-semibold mb-2 text-center md:text-left">{decodedTitle}</p>
                            <p className="text-base mb-4 text-center md:text-left">{decodedDescription}</p>

                            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                <div className="flex items-center mb-4 md:mb-0">
                                    <button className="px-3 py-1 border rounded" onClick={decrement}>-</button>
                                    <input
                                        className="mx-2 px-3 py-1 border rounded text-center"
                                        style={{ width: "64px" }}
                                        type="text"
                                        value={count}
                                        onChange={inputCounter}
                                    />
                                    <button className="px-3 py-1 border rounded" onClick={increment}>+</button>

                                    {count !== product.initialCount && (
                                        <button
                                            className="ml-2 px-2 py-1 bg-yellow-500 text-white rounded text-xs md:text-sm"
                                            onClick={() => updateQuantity(product.nonce, count)}
                                        >
                                            Update
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row p-4">
                        <button className="py-2 px-4 font-semibold bg-black text-white rounded flex-1 mb-2 md:mb-0 md:mr-2" onClick={onClose}>Continue Shopping</button>
                        <button
                            className="py-2 px-4 font-semibold bg-red-500 text-white rounded hover:bg-red-700 flex-1"
                            onClick={() => (window.location.href = 'https://one.trc4r.dev/app/cart')}
                        >
                            View Cart & Checkout
                        </button>

                    </div>
                </div>
            </div>

        );
    }

    //save product
    const handleProductSave = (product) => {
        const { nonce } = product;

        const eid = localStorage.getItem('default');
        const a = localStorage.getItem('nonce');

        setProduct((prevProduct) => ({
            ...prevProduct,
            product: {
                ...prevProduct.product,
                saved: 1,
            },
        }));


        const payload = {
            eid: eid,
            endpoint: "v1/collection/add/items",
            a: a,
            products: [nonce]
        };


        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {
                    console.log("product saved")
                } else {
                    alert(data.error || "Failed to save the product");
                }
            })
            .catch((error) => {
                console.error("Save product error:", error);
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    };

    //unsave product
    const productUnsave = (nonce) => {
        const eid = localStorage.getItem('default');
        const a = localStorage.getItem("nonce");


        setProduct((prevProduct) => ({
            ...prevProduct,
            product: {
                ...prevProduct.product,
                saved: 0,
            },
        }));


        const payload = {
            eid: eid,
            endpoint: "v1/collection/delete/items",
            products: [nonce],
            a: a
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === 1 && data.response && data.response === "OK") {
                    console.log("product unsaved")
                }
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    };
    const isSaved = product.product.saved === 1;


    return (
        <div
            className="relative min-h-screen bg-gray-100"
            style={{ paddingTop: '150px' }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={priceLookUpLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="flex justify-center items-center min-h-screen">
                <div className="max-w-6xl w-full border border-gray-300 p-8 rounded-lg shadow-lg relative">

                    <div className="mb-4 flex items-center space-x-2 hover:text-blue-500 cursor-pointer" onClick={goBack}>
                        <ArrowBackIcon className="hover:text-blue-500" />
                        <span className="font-semibold hover:text-blue-500">Products</span>
                    </div>

                    <div className="flex flex-col md:flex-row">
                        <div className="md:w-1/2 flex justify-center items-center relative">
                            <img
                                src={product.images.images_primary}
                                alt={cleanTitle}
                                className="w-full h-auto object-cover rounded-lg"
                            />

                            {Number(product?.product?.cart) > 0 && (
                                <Badge.Ribbon
                                    text={`Added To Cart (${product.product.cart})`}
                                    color="red"
                                    style={{ zIndex: 1, width: 'auto', height: 'auto', marginTop: '-15rem' }}
                                />
                            )}
                        </div>

                        <div className="md:w-1/2 mt-4 md:mt-0 md:ml-8 flex flex-col justify-center space-y-6">
                            <h1 className="text-3xl font-bold">{cleanTitle}</h1>
                            {cleanDescription.includes("\n") ? (
                                cleanDescription.split("\n").map((line, index) => (
                                    line.startsWith("•") ? (
                                        <li key={index} className="ml-4 list-disc">{line.slice(2)}</li>
                                    ) : (
                                        <p key={index} className="mb-2">{line}</p>
                                    )
                                ))
                            ) : (
                                <p className="text-gray-700">{cleanDescription}</p>
                            )}

                            {/* <p className="font-bold text-center md:text-left">{currencySymbol}{price === 0 ? "0.00" : `${price}`}</p> */}

                            <p className="font-bold text-center md:text-left">
                                {isLoading
                                    ? "Loading..."
                                    : `${currencySymbol}${price === 0 ? "0.00" : `${price}`}`
                                }
                            </p>

                            <div>
                                <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly />
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <button
                                    className="px-4 py-2 rounded add-to-cart-button"
                                    onClick={() =>
                                        addToNttCart({
                                            id: product.id,
                                            nonce: product.product.nonce,
                                            title: cleanTitle,
                                            Description: product.product.short_description,
                                            image: product.images.images_primary,
                                            price: price,
                                            // Use the current cart quantity or default to 0
                                            initialCount: product.product.cart || 0,
                                        })
                                    }
                                >
                                    Add to Cart
                                </button>


                                <Tooltip title={isSaved ? "Item saved" : "Save item"} arrow>
                                    <div
                                        className="cursor-pointer relative ml-auto"
                                        onClick={() => {
                                            if (isSaved) {
                                                productUnsave(product.product.nonce);
                                            } else {
                                                handleProductSave({
                                                    //do we need to send id & price? lol
                                                    id: product.id,
                                                    price: price,
                                                    nonce: product.product.nonce,
                                                });
                                            }
                                        }}
                                    >
                                        {isSaved ? <SavedIcon /> : <SaveIcon />}
                                    </div>
                                </Tooltip>
                            </div>


                        </div>
                    </div>

                    <div className="mt-8">
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{
                                width: '100%',
                            }}
                        >
                            <Card title="Details" size="small">
                                <h4 className="text-gray-500 text-xs mb-2">Long Description</h4>
                                {cleanDescription.includes("\n") ? (
                                    cleanDescription.split("\n").map((line, index) => (
                                        <p key={index} className="mb-2">{line}</p>
                                    ))
                                ) : (
                                    <p>{cleanDescription}</p>
                                )}
                                <h4 className="text-gray-500 text-xs mb-2">Short Description</h4>
                                <p>{product.product.short_description}</p>
                                <h4 className="text-gray-500 text-xs mb-2">Category ID</h4>
                                <p>{product.identifiers.category_id}</p>
                                <h4 className="text-gray-500 text-xs mb-2">Tags</h4>
                                <p>{product.product.tags}</p>
                                <h4 className="text-gray-500 text-xs mb-2">Item Number</h4>
                                <p>{product.ln.item_number}</p>
                                <h4 className="text-gray-500 text-xs mb-2">SKU</h4>
                                <p>{product.identifiers.sku}</p>
                            </Card>

                            <Card title="Categories" size="small">
                                <h4 className="text-gray-500 text-xs mb-2">Categories</h4>
                                <div>
                                    {product.taxonomy && product.taxonomy.length > 0 ? (
                                        renderTaxonomy(product.taxonomy)
                                    ) : (
                                        <p>No taxonomy data available.</p>
                                    )}
                                </div>
                            </Card>


                            <Card title="Tags" size="small">
                                <h4 className="text-gray-500 text-xs mb-2">Utility</h4>
                                <div>
                                    {product.tags && product.tags.utility && product.tags.utility.length > 0 ? (
                                        product.tags.utility.map((utility) => (
                                            <p key={utility.nonce}>{utility.long_description || "No description available"}</p>
                                        ))
                                    ) : (
                                        <p>No utility tags available For this Product.</p>
                                    )}
                                </div>

                                <h4 className="text-gray-500 text-xs mb-2">Product</h4>
                                <div>
                                    {product.tags && product.tags.priority && product.tags.priority.length > 0 ? (
                                        product.tags.priority.map((priority) => (
                                            <p key={priority.nonce}>{priority.long_description || "No description available"}</p>
                                        ))
                                    ) : (
                                        <p>No Product tags available For this Product.</p>
                                    )}
                                </div>
                            </Card>

                        </Space>
                    </div>


                </div>
            </div>

            {modalOpen && popupProductData && (
                <AddToCartPopup
                    product={selectedProduct}
                    onClose={handleClosePopup}
                    updateQuantity={(nonce, count) => {
                        updateNttCart(nonce, count);
                    }}
                    count={count}
                    initialCount={product?.product?.cart ?? 1}
                    inputCounter={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (!isNaN(value)) setPopupCount(value);
                    }}
                />
            )}

        </div>
    );
}
