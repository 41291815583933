import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

export default function Login() {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  function handleLogin(e) {
    e.preventDefault();

    fetch('admin/ipAddress.php')
      .then(response => response.json())
      .then(data => {
        const clientIP = data.ip;

        const payload = {
          username: email,
          password: password,
          ip: clientIP,
          endpoint: "v1/login/verify",
        };

        return fetch('/admin/postData.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
      })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          const defaultNonce = data.response.default;
          const time_offset = data.response.time_offset;
          const userNonce = data.response.api;
          const business_partner_id = data.response.customer.business_partner_id;
          const currency = data.response.customer.currency;
          const customerEmail = data.response.customer.email;

          const customer_username = data.response.customer.username
          const first_name = data.response.customer.first_name
          const last_name = data.response.customer.last_name
          const phone = data.response.customer.phone

          const company = data.response.customer.company;
          const site_location = data.response.customer.site_location;

          sessionStorage.setItem('auth_token', data.response.token);

          localStorage.setItem('time_offset', time_offset);
          localStorage.setItem('default', defaultNonce);
          localStorage.setItem('nonce', userNonce);
          localStorage.setItem('business_partner_id', business_partner_id);
          localStorage.setItem('email', customerEmail);

          localStorage.setItem('company', company);
          localStorage.setItem('site_location', site_location)
          localStorage.setItem('currency', currency)

          localStorage.setItem('customer_username', customer_username);
          localStorage.setItem('first_name', first_name);
          localStorage.setItem('last_name', last_name);
          localStorage.setItem('phone', phone);

          if (data.response.time_offset !== undefined) {
            localStorage.setItem('username', email);
            localStorage.setItem('default', defaultNonce);
            localStorage.setItem('nonce', userNonce);
          }

          const catalogList = [];
          const catalogEnum = data.response.customer.catalog_enum;

          catalogEnum.forEach(catalogItem => {
            const catalogValues = Object.values(catalogItem)[0];

            const firstItem = catalogValues[0];
            if (firstItem !== "Select" && firstItem !== "*** Remove Catalog ***") {
              catalogList.push(firstItem);
            }
          });

          localStorage.setItem('customer_catalogs', JSON.stringify(catalogList));

          navigate('/app/home');
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setError(true);
      });
  }





  return (
    <div className="flex items-center justify-center h-screen">
      <form
        onSubmit={handleLogin}
        className="flex flex-col self-center px-8 pt-6 pb-8 m-12 mb-4 bg-white rounded shadow-md"
      >
        <div>{error && "Your credentials are incorrect"}</div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-bold text-grey-darker"
            for="username"
          >
            Username
          </label>
          <input
            className="w-full px-3 py-2 border rounded shadow appearance-none text-grey-darker"
            id="username"
            onChange={(e) => setEmail(e?.target?.value)}
            type="text"
            placeholder="Username"
          />
        </div>
        <div className="mb-6">
          <label
            className="block mb-2 text-sm font-bold text-grey-darker"
            for="password"
          >
            Password
          </label>
          <input
            className="w-full px-3 py-2 mb-3 border rounded shadow appearance-none border-red text-grey-darker"
            id="password"
            type="password"
            onChange={(e) => setPassword(e?.target?.value)}
            placeholder="******************"
          />
          <p className="text-xs italic text-red">Please choose a password.</p>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="px-4 py-2 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
            type="submit"
          >
            Sign In
          </button>

        </div>
      </form>
    </div>
  );
}

