import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNotifications } from '../context/NotificationContext';
import { DeleteIcon, NoDataIcon } from '../assets/icons/hiking';
import { Backdrop, CircularProgress, Dialog, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import wheelImage from "../assets/images/wheel.png";

export default function Cart() {
    const navigate = useNavigate();
    const { triggerCartUpdate } = useCart();
    const { showNotification } = useNotifications();
    const [products, setProducts] = useState([]);
    const [changedProductIds, setChangedProductIds] = useState(new Set());
    const [priceLookUpLoading, setPriceLookUpLoading] = useState(false);

    const [isFormVisible, setFormVisible] = useState(false);
    const [isCartVisible, setCartVisible] = useState(true);
    const [isBillingVisible, setBillingVisible] = useState(false);

    // const [cartItemCount, setCartItemCount] = useState(false);
    const cartItemCount = products.length;

    const currency = localStorage.getItem("currency");
    const currencySymbol = currency === "EUR" ? "€" : currency === "USD" ? "$" : "";

    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "cart";
        const id = localStorage.getItem("nonce");

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        setPriceLookUpLoading(true);

        const url = protocolpluspath() +
            "admin/getData.php?eid=" + eid + "&type=" + type + "&id=" + id;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success && data.data && data.data.response) {
                    const itemNumbers = data.data.response.map(item => item.cart.item_number);

                    if (itemNumbers.length > 0) {
                        cartProductPrices(itemNumbers);
                    }

                    const transformedProducts = data.data.response.map(item => ({
                        id: item.id,
                        item_number: item.cart.item_number,
                        nonce: item.cart.nonce,
                        title: item.cart.title,
                        description: item.cart.description,
                        serverQuantity: parseInt(item.cart.quantity, 10),
                        draftQuantity: parseInt(item.cart.quantity, 10),
                        image: item.cart.images.images_primary,
                        custom_1: item.cart.custom.custom_1,
                        custom_2: item.cart.custom.custom_2,
                        custom_3: item.cart.custom.custom_3,
                        custom_4: item.cart.custom.custom_4,
                        price: 0,
                    }));

                    setProducts(transformedProducts.reverse());
                } else {
                    console.error("Unexpected response format:", data);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    }, []);



    const [isPriceLoading, setIsPriceLoading] = useState(false);

    function cartProductPrices(itemNumbers) {
        const eid = localStorage.getItem("default");
        const customer_number = localStorage.getItem('business_partner_id');
        const site_location = localStorage.getItem('site_location');
        const currency = localStorage.getItem('currency')

        const payload = {
            eid: eid,
            endpoint: 'v1/get/price',
            item_number: itemNumbers,
            business_partner_id: customer_number,
            site_location: site_location,
            currency: currency
        };

        setIsPriceLoading(true);

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response && data.response.data) {
                    const priceData = Array.isArray(data.response.data)
                        ? data.response.data
                        : [data.response.data];

                    const prices = priceData.reduce((acc, item) => {
                        acc[item.itemCode] = item.netAmount;
                        return acc;
                    }, {});

                    setProducts(prevProducts =>
                        prevProducts.map(product => ({
                            ...product,
                            price: prices[product.item_number] ?? 0
                        }))
                    );
                } else {
                    console.error("Unexpected price response format:", data);
                }
            })
            .catch(error => {
                console.error("Error fetching prices:", error);
            })
            .finally(() => {
                setIsPriceLoading(false);
            });
    }


    useEffect(() => {
        async function lnAddressLookup() {
            try {
                const customer_number = localStorage.getItem('business_partner_id');
                const eid = localStorage.getItem('default');

                const payload = {
                    eid: eid,
                    endpoint: "v1/ln/address/lookup",
                    business_partner_id: customer_number,
                };

                const response = await fetch('/admin/postData.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const data = await response.json();

                if (data.success === 1 && data.response && data.response.data) {
                    const fetchedAddressData = data.response.data;

                    const newAddress = {
                        id: String(fetchedAddressData.addressCode || 'address1'),
                        name: fetchedAddressData.name?.[0] || '',
                        phone: fetchedAddressData.telephone || '',
                        street: fetchedAddressData.streetName
                            ? `${fetchedAddressData.streetName} ${fetchedAddressData.houseNumber || ''}`.trim()
                            : '',
                        area: fetchedAddressData.additionalAddress || '',
                        city: fetchedAddressData.cityDescription || '',
                        country: fetchedAddressData.countryDescription || '',
                    };

                    setAddresses([newAddress]);
                }
            } catch (err) {
                // alert("Something went wrong");
            }
        }

        lnAddressLookup();
    }, []);


    // Increment quantity
    const incrementQuantity = (productId) => {
        setProducts(products.map(product =>
            product.id === productId
                ? { ...product, draftQuantity: product.draftQuantity + 1 }
                : product
        ));
        setChangedProductIds(prev => new Set(prev).add(productId));
    };

    // Decrement quantity
    const decrementQuantity = (productId) => {
        setProducts(products.map(product =>
            (product.id === productId && product.draftQuantity > 1)
                ? { ...product, draftQuantity: product.draftQuantity - 1 }
                : product
        ));
        setChangedProductIds(prev => new Set(prev).add(productId));
    };

    // Manual Change
    const handleQuantityChange = (productId, newQuantity) => {
        setProducts(products.map(product =>
            product.id === productId
                ? { ...product, draftQuantity: newQuantity }
                : product
        ));
        setChangedProductIds(prev => new Set(prev).add(productId));
    };


    const updateProductInServer = (productId) => {
        const product = products.find(p => p.id === productId);
        if (!product) return;

        const eid = localStorage.getItem("default");
        const nonceVal = localStorage.getItem("nonce");

        setPriceLookUpLoading(true);

        const payload = {
            a: nonceVal,
            eid: eid,
            endpoint: "v1/cart/item/update",
            nonce: product.nonce,
            value: product.draftQuantity,
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setProducts(prevProducts =>
                        prevProducts.map(p => {
                            if (p.id === productId) {
                                return {
                                    ...p,
                                    serverQuantity: p.draftQuantity,
                                };
                            }
                            return p;
                        })
                    );

                    // Remove productId from changedProductIds
                    setChangedProductIds(prev => {
                        const updatedSet = new Set(prev);
                        updatedSet.delete(productId);
                        return updatedSet;
                    });

                    // Optionally trigger a re-fetch if desired
                    // triggerCartUpdate();
                } else {
                    console.error('Failed to update product:', data);
                }
            })
            .catch(error => {
                alert("Error Updating Product");
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    };



    const deleteProduct = (productId) => {
        const product = products.find(p => p.id === productId);
        if (product) {
            const eid = localStorage.getItem("default");
            const a = localStorage.getItem("nonce");

            const payload = {
                eid: eid,
                a: a,
                endpoint: "v1/cart/item/remove",
                nonce: product.nonce,
            };
            setPriceLookUpLoading(true);

            fetch('/admin/postData.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setProducts(products.filter(p => p.id !== productId));
                        triggerCartUpdate();
                    } else {
                        console.error('Failed to delete product:', data);
                    }
                })
                .catch(error => {
                    alert("Error Deleting Product");
                })
                .finally(() => {
                    setPriceLookUpLoading(false);
                });
        }
    };

    // SUBTOTAL: based on serverQuantity * price
    const subtotal = products.reduce((sum, product) => {
        const numericalPrice = parseFloat((product.price ?? 0).toString().replace(/,/g, ""));
        return sum + (numericalPrice * product.serverQuantity);
    }, 0);


    const shippingInformation = () => {
        setCartVisible(!isCartVisible);
        setFormVisible(!isFormVisible);
    };

    const handleButtonClick = (e) => {
        if (isCartVisible) {
            // First click: Go to the form
            setCartVisible(false);
            setFormVisible(true);
        } else if (isFormVisible) {
            // Second click: Go to billing
            setFormVisible(false);
            setBillingVisible(true);
        } else if (isBillingVisible) {
            // Third click: Perform final action
            createOrderButton(e);
        }
    };

    // checkout button (create order payload)
    const [createOrder] = useState({
        date_created_gmt: '',
        order_id: '',
        address_index: '55555555',
        live_test: '0',
        shipping_total: '1.0',
        line_items: []
    });

    const [orderDetails, setOrderDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    // shipping info
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');

    //Billing & Payment
    const [poNumber, setPoNumber] = useState('');
    const [shippingInstructions, setShippingInstructions] = useState('');

    const createOrderButton = (e) => {
        e.preventDefault();

        setLoading(true);

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('.')[0]; //"2024-09-01T14:52:21" format
        const timestamp = currentDate.getTime().toString();

        const items = products.map(product => ({
            product_nonce: product.nonce,
            quantity: product.serverQuantity
        }));

        const payload = {
            ...createOrder,
            date_created_gmt: formattedDate,
            order_id: timestamp,

            eid: localStorage.getItem("default"),
            endpoint: "v1/bridge/ln/create/order/standalone",

            name,
            last_name: lastName,
            address_1: address1,
            address_2: address2,
            city,
            state,
            zip,
            country,
            po_number: poNumber,
            shipping_instructions: shippingInstructions,


            items
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };

        fetch('/admin/postData.php', requestOptions)
            .then(response => response.text())
            .then(responseText => {
                try {
                    const jsonMatch = responseText.match(/\{(?:[^{}]|(?:\{[^{}]*\}))*\}/);

                    if (jsonMatch) {
                        const jsonResponse = JSON.parse(jsonMatch[0]);

                        if (jsonResponse && jsonResponse.success === 1) {
                            const orderDetails = {
                                orderType: "TEST",
                                wooOrderId: timestamp,
                                addressId: "",
                                lnSalesOrderId: jsonResponse.response,
                                date: currentDate.toLocaleString()
                            };
                            setOrderDetails(orderDetails);
                            setShowSuccessPopup(true);
                            createNttOrder(formattedDate, timestamp, payload);

                        } else {
                            const Notificationpayload = {
                                response: false,
                                subject: "Order",
                                operation: "Creating",
                                context: "",
                            };
                            showNotification(Notificationpayload);
                        }
                    } else {
                        const Notificationpayload = {
                            response: false,
                            subject: "Order",
                            operation: "Creating",
                            context: "",
                        };
                        showNotification(Notificationpayload);
                    }
                } catch (error) {
                    const Notificationpayload = {
                        response: false,
                        subject: "Order",
                        operation: "Creating",
                        context: "",
                    };
                    showNotification(Notificationpayload);
                } finally {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };

    const createNttOrder = (formattedDate, timestamp, payload) => {

        const secondaryPayload = {
            order_number: timestamp,
            po_number: payload.po_number,
            status: "Pending",
            total: "0.00",
            taxes: "0.00",

            username: localStorage.getItem("customer_username"),
            first_name: localStorage.getItem("first_name"),
            last_name: localStorage.getItem("last_name"),
            phone: localStorage.getItem("phone"),
            email: localStorage.getItem("email"),
            user_nonce: localStorage.getItem("nonce"),

            promised_date: "2024-11-30 15:00:00",


            address1: payload.address_1,
            address2: payload.address_2,
            city: payload.city,
            state: payload.state,
            zip: payload.zip,
            country: payload.country,
            shipping_instructions: payload.shipping_instructions,
            items: payload.items,

            endpoint: "v1/order/add",
            eid: localStorage.getItem("default"),
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(secondaryPayload)
        };

        fetch('/admin/postData.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log('Secondary response:', data);
                if (data.success) {
                    console.log('Secondary payload sent successfully!');
                    emptyCart();
                } else {
                    console.error('Error sending secondary payload:', data);
                }
            })
            .catch(error => {
                console.error('Error in secondary request:', error);
            });
    };

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
    };

    // response
    const [countdown, setCountdown] = useState(10);
    const [redirectCancelled, setRedirectCancelled] = useState(false);

    useEffect(() => {
        let timer;
        if (showSuccessPopup && !redirectCancelled) {
            setCountdown(10);
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown > 1) {
                        return prevCountdown - 1;
                    } else {
                        clearInterval(timer);
                        localStorage.removeItem('cart');
                        navigate('/app/home');
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [showSuccessPopup, redirectCancelled]);

    const handleCancelRedirect = () => {
        setRedirectCancelled(true);
    };

    const handleClose = () => {
        localStorage.removeItem('cart');
        handleClosePopup();
        navigate('/app/home');
    };

    const emptyCart = () => {
        const eid = localStorage.getItem("default");
        const a = localStorage.getItem("nonce");
        setPriceLookUpLoading(true);

        const payload = {
            a: a,
            eid: eid,
            endpoint: "v1/cart/empty",
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setProducts([]); // clear the products.. || not sure if this would work tho
                    triggerCartUpdate();
                } else {
                    console.error('Failed to update product:', data);
                }
            })
            .catch(error => {
                alert("Error Updating Product")
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });

    };

    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [showExistingAddresses, setShowExistingAddresses] = useState(true);


    const showAddressForm = () => {
        setShowExistingAddresses(false);
    };

    const backToAddresses = () => {
        setShowExistingAddresses(true);
    };



    // -- Wheel Weight Validation.
    const hasZero = products.some((p) => p.custom_3 === "0");
    const hasWeight = products.some((p) => p.custom_3 === "1" || p.custom_3 === "2");
    const isMixedCart = hasZero && hasWeight;

    // -- Vendor Validation (only applies to weight items)
    const wheelWeightProducts = products.filter(
        (p) => p.custom_3 === "1" || p.custom_3 === "2"
    );
    const vendors = wheelWeightProducts.map((p) => p.custom_4)
    const uniqueVendors = [...new Set(vendors)];
    const hasMultipleVendors = uniqueVendors.length > 1;
    const isVendorMismatch = hasWeight && hasMultipleVendors;


    // display error messages
    function getCartErrorMessage() {
        if (isVendorMismatch) {
            return "You cannot mix different Wheel Weight vendors in the same Cart.";
        }
        if (isMixedCart) {
            return "Wheel weight and regular items can't be in the same cart.";
        }
        if (isBlockedForLead) {
            return "Lead Wheel Weights are restricted from shipping to WA, MD, CA, IL, ME, VT, MN, and NY.";
        }
        return "";
    }


    // - filled address validation (address restriction for Lead Wheel Weight)
    const [isAddressValid, setIsAddressValid] = useState(false);

    const handleAddressChange = (id) => {
        setSelectedAddress(id);
        setIsAddressValid(!!id)
    };

    useEffect(() => {
        if (!showExistingAddresses) {
            const isFormComplete =
                name &&
                lastName &&
                address1 &&
                city &&
                state &&
                zip &&
                country;
            setIsAddressValid(isFormComplete);
        }
    }, [name, lastName, address1, city, state, zip, country, showExistingAddresses]);

    const restrictedStates = [
        "WA", "Washington",
        "MD", "Maryland",
        "CA", "California",
        "IL", "Illinois",
        "ME", "Maine",
        "VT", "Vermont",
        "MN", "Minnesota",
        "NY", "New York"
    ];

    function isRestrictedState(inputState) {
        const normalizedInput = inputState.trim().toLowerCase();
        for (let i = 0; i < restrictedStates.length; i += 2) {
            const abbrev = restrictedStates[i].toLowerCase();
            const fullName = restrictedStates[i + 1].toLowerCase();

            if (normalizedInput === abbrev || normalizedInput === fullName) {
                return true;
            }
        }
        return false;
    }
    const hasLeadWheelWeights = products.some(p => p.custom_3 === "2");
    const isBlockedForLead = hasLeadWheelWeights && isRestrictedState(state);


    // -- MOQ Validation
    const moqViolations = products.filter(p =>
        p.custom_1 &&
        parseInt(p.serverQuantity, 10) < parseInt(p.custom_1, 10)
    );
    const hasMoqViolation = moqViolations.length > 0;


    // -- validation for Allowed increment 
    const hasIncrementViolation = products.some(p => {
        const increment = parseInt(p.custom_2, 10);
        if (!increment || increment <= 0) {
            return false;
        }
        return parseInt(p.serverQuantity, 10) % increment !== 0;
    });

    const isInvalidCart = isMixedCart || isVendorMismatch || isBlockedForLead || hasIncrementViolation;


    function handleCartButtonClick(nonce) {
        const url = `/app/product?nonce=${nonce}`;

        const newTab = window.open(url, '_blank');
        if (newTab) newTab.focus();
    }


    return (
        <div className="flex flex-col lg:flex-row justify-center items-start p-4 mx-4 lg:mx-14 mt-3">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex flex-col w-full lg:w-2/3 justify-center items-center pt-4"
                style={{ paddingTop: '150px' }}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={priceLookUpLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {products.length > 0 ? (

                    <div className='flex flex-col w-full'>

                        <div className="flex flex-col sm:flex-row items-center sm:justify-between space-y-4 sm:space-y-0">
                            <span className='font-semibold text-xl sm:text-2xl'>My Cart ({cartItemCount})</span>
                            <div className="flex items-center space-x-4">
                                {!isCartVisible && (
                                    <button
                                        onClick={shippingInformation}
                                        className="font-semibold text-black py-2 px-4 rounded border border-black bg-gray-100 hover:bg-gray-200">
                                        Edit
                                    </button>
                                )}
                                {isCartVisible && (
                                    <button
                                        onClick={emptyCart}
                                        className="text-black underline hover:text-gray-600 font-medium self-end sm:self-center ml-auto">
                                        Empty Cart
                                    </button>
                                )}
                            </div>
                        </div>


                        <hr className="w-full my-3 border-gray-300 border-1" />

                        {isCartVisible && (
                            <div className="space-y-4">

                                {products.map((product) => {

                                    const draftQuantity = parseInt(product.draftQuantity, 10) || 0;

                                    // MOQ logic
                                    const moqValue = parseInt(product.custom_1, 10);
                                    const isMoqValid = !Number.isNaN(moqValue) && moqValue > 0;
                                    const isMoqViolation = isMoqValid && draftQuantity < moqValue;

                                    // Increment logic
                                    const increment = parseInt(product.custom_2, 10) || 0;
                                    const hasIncrementViolation =
                                        increment > 0 && (draftQuantity % increment !== 0);

                                    return (
                                        <div key={product.id} className="relative flex flex-col sm:flex-row w-full sm:items-center mb-4 rounded-lg p-4" style={{ borderBottom: '1px solid #e5e7eb' }}>
                                            <img src={product.image} alt={product.title} onClick={() => handleCartButtonClick(product.nonce)} className="w-full sm:w-36 mb-4 sm:mb-0 sm:mr-4 cursor-pointer" />

                                            <div className="flex flex-col flex-grow">
                                                <div className="flex justify-between items-center">
                                                    <div className='cursor-pointer' onClick={() => handleCartButtonClick(product.nonce)}>
                                                        <span
                                                            className="text-sm font-medium block cursor-pointer"
                                                        >
                                                            {product.title}
                                                        </span>
                                                        <span className="text-xs text-gray-500 block">{product.description}</span>
                                                    </div>
                                                    <div className="cursor-pointer" onClick={() => deleteProduct(product.id)}>
                                                        <DeleteIcon />
                                                    </div>
                                                </div>

                                                <span className="mt-4 sm:mt-10">{product.item_number}</span>

                                                <div className="flex flex-col sm:flex-row justify-between items-center flex-grow mt-4 sm:mt-0">
                                                    {/* Quantity Controls */}
                                                    <div className="flex items-center">
                                                        <button
                                                            className="px-3 py-1 border rounded"
                                                            onClick={() => decrementQuantity(product.id)}
                                                        >
                                                            -
                                                        </button>
                                                        <input
                                                            className="mx-2 px-3 py-1 border rounded text-center"
                                                            style={{ width: '64px' }}
                                                            type="text"
                                                            value={product.draftQuantity}
                                                            onChange={(e) =>
                                                                handleQuantityChange(product.id, Math.max(1, parseInt(e.target.value) || 0))
                                                            }
                                                        />
                                                        <button
                                                            className="px-3 py-1 border rounded"
                                                            onClick={() => incrementQuantity(product.id)}
                                                        >
                                                            +
                                                        </button>
                                                        {changedProductIds.has(product.id) && (
                                                            <button
                                                                className="px-2 py-1 bg-yellow-500 text-white rounded text-xs ml-2"
                                                                onClick={() => updateProductInServer(product.id)}
                                                            >
                                                                Update
                                                            </button>
                                                        )}
                                                        {/* show one at the time */}
                                                        {(() => {
                                                            if (isMoqViolation) {
                                                                return (
                                                                    <div className="text-red-500 text-xs italic mt-1 pl-2">
                                                                        This product requires a minimum quantity of {moqValue}
                                                                    </div>
                                                                );
                                                            } else if (hasIncrementViolation) {
                                                                return (
                                                                    <div className="text-red-500 text-xs italic mt-1 pl-2">
                                                                        This item must be ordered in increments of {increment}.
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })()}
                                                    </div>
                                                    <div className="flex items-center justify-end flex-grow">
                                                        <span className="text-black font-semibold">
                                                            {isPriceLoading
                                                                ? "Loading..."
                                                                : product.price !== null && product.price !== undefined
                                                                    ? (() => {
                                                                        const quantity = product.serverQuantity || 0;
                                                                        const numericalPrice = parseFloat(product.price.toString().replace(/,/g, ""));
                                                                        const total = (numericalPrice * quantity).toFixed(2);
                                                                        const unit = numericalPrice.toFixed(2);
                                                                        return `${currencySymbol}${unit} (${currencySymbol}${total})`;
                                                                    })()
                                                                    : "Price not available"
                                                            }

                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div className='pb-5'>
                            <div className="flex flex-col sm:flex-row items-center sm:justify-between space-y-4 sm:space-y-0">
                                <span className='font-semibold text-xl sm:text-2xl'>Shipping Information</span>
                                {!isFormVisible && (
                                    <button onClick={shippingInformation} className="font-semibold text-black py-2 px-4 rounded border border-black bg-gray-100 hover:bg-gray-200">Edit</button>
                                )}
                            </div>
                            <hr className="w-full my-3 border-gray-300 border-1" />

                            {isFormVisible && (
                                <form className="space-y-4">
                                    {showExistingAddresses ? (
                                        <>
                                            <div className="flex justify-between items-center mb-4">
                                                <button
                                                    type="button"
                                                    onClick={showAddressForm}
                                                    className="font-semibold text-black py-2 px-4 rounded border border-black bg-gray-100 hover:bg-gray-200"
                                                >
                                                    Add a New Address
                                                </button>
                                            </div>

                                            <div className="space-y-4">
                                                {addresses.map((address) => (
                                                    <div
                                                        key={address.id}
                                                        className={`border p-4 rounded ${selectedAddress === address.id ? 'border-blue-500' : 'border-gray-300'
                                                            }`}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="rounded-full"
                                                            checked={selectedAddress === address.id}
                                                            onChange={() => handleAddressChange(address.id)}
                                                        />
                                                        <div className="mt-2 font-semibold text-lg">{address.name}</div>
                                                        <p className="text-sm">{address.phone}</p>
                                                        <p className="text-sm">{address.street}</p>
                                                        <p className="text-sm">
                                                            {address.area}, {address.city}
                                                        </p>
                                                        <p className="text-sm">{address.country}</p>
                                                    </div>
                                                ))}
                                            </div>

                                        </>
                                    ) : (
                                        <>
                                            <div className="flex justify-between items-center mb-4">
                                                <span className="font-semibold text-xl sm:text-2xl">Add New Address</span>
                                                <button
                                                    type="button"
                                                    onClick={backToAddresses}
                                                    className="font-semibold text-black py-2 px-4 rounded border border-black bg-gray-100 hover:bg-gray-200"
                                                >
                                                    Back to Addresses
                                                </button>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Name
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200"
                                                        id="grid-first-name"
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        Last Name
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                        id="grid-last-name"
                                                        type="text"
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full px-3">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-address">
                                                        Address 1
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                        id="grid-address"
                                                        type="text"
                                                        value={address1}
                                                        onChange={(e) => setAddress1(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full px-3">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-address2">
                                                        Address 2
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                        id="grid-address2"
                                                        type="text"
                                                        value={address2}
                                                        onChange={(e) => setAddress2(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-city">
                                                        City
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                        id="grid-city"
                                                        type="text"
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                                        State / Province
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                        id="grid-state"
                                                        type="text"
                                                        value={state}
                                                        onChange={(e) => setState(e.target.value)}
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-zip">
                                                        Postal / Zip Code
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                        id="grid-zip"
                                                        type="text"
                                                        value={zip}
                                                        onChange={(e) => setZip(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-2">
                                                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                                                        Country
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                        id="grid-country"
                                                        type="text"
                                                        value={country}
                                                        onChange={(e) => setCountry(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </form>
                            )}

                        </div>

                        <div className='pb-5'>
                            <div className="space-y-4">
                                <span className='font-semibold text-xl sm:text-2xl'>Billing & Payment</span>
                            </div>

                            {isBillingVisible && (
                                <form className="mt-4 space-y-6">
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-address">
                                                P.O Number:
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200"
                                                id="grid-address"
                                                type="text"
                                                value={poNumber}
                                                onChange={(e) => setPoNumber(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label
                                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-comments">
                                                Comments/Shipping Instruction
                                            </label>
                                            <textarea
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white resize-none"
                                                id="grid-comments"
                                                rows="4"
                                                value={shippingInstructions}
                                                onChange={(e) => setShippingInstructions(e.target.value)}
                                                style={{ maxWidth: "100%" }}
                                            />
                                        </div>
                                    </div>

                                </form>
                            )}

                            <hr className="w-full my-3 border-gray-300 border-1" />
                        </div>

                    </div>

                ) : (
                    <div className="flex flex-col items-center">
                        <span className="text-xl font-semibold">Your cart is empty.</span>
                        <NoDataIcon />
                    </div>
                )
                }

            </motion.div >

            {/* Order summary */}
            <div style={{ paddingTop: '150px' }} className="w-full lg:ml-8 lg:w-1/3 mt-6 lg:mt-0">
                <div className="py-5 bg-gray-200 p-4 rounded-lg">
                    <h2 className="font-bold text-lg mb-4">Order Summary</h2>
                    <div className="flex justify-between mb-2">
                        <span>Subtotal</span>
                        <span>{currencySymbol}{subtotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="flex justify-between mb-2">
                        <span>Estimated Tax</span>
                        <span>{currencySymbol}0.00</span>
                    </div>
                    <div className="flex justify-between mb-2">
                        <span>Estimated Shipping</span>
                        <span>{currencySymbol}0.00</span>
                    </div>

                    <hr className="my-6 border-gray-300 border-1" />

                    <div className="flex justify-between mb-4 font-bold">
                        <span className="text-lg">Estimated Total</span>
                        <span className="text-xl">{currencySymbol}{subtotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>

                    <hr className="my-6 border-gray-300 border-1" />

                    <div>
                        {isInvalidCart && (
                            <p className="text-red-500 text-sm mt-2">
                                {getCartErrorMessage()}
                            </p>
                        )}

                        <button
                            className="w-full text-white py-2 rounded bg-black disabled:opacity-50 disabled:bg-gray-600 disabled:cursor-not-allowed"
                            onClick={handleButtonClick}
                            disabled={
                                (!isFormVisible && !isCartVisible && !isBillingVisible) ||
                                isInvalidCart ||
                                (isFormVisible && !isAddressValid) ||
                                hasMoqViolation
                            }
                        >
                            {isCartVisible
                                ? "Continue"
                                : isFormVisible
                                    ? "Checkout"
                                    : isBillingVisible
                                        ? "Place Order"
                                        : "Submit"}
                        </button>
                    </div>

                </div>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Dialog open={showSuccessPopup} onClose={handleClose}>
                    <DialogTitle className="p-4 text-lg font-semibold">Order Created Successfully!</DialogTitle>
                    <DialogContent className="p-4">
                        <Typography className="mb-2">
                            <span className="font-semibold">Order Type:</span> {orderDetails.orderType}
                        </Typography>
                        <Typography className="mb-2">
                            <span className="font-semibold">Woo Order ID:</span> {orderDetails.wooOrderId}
                        </Typography>
                        <Typography className="mb-2">
                            <span className="font-semibold">Address ID:</span> {orderDetails.addressId || ""}
                        </Typography>
                        <Typography className="mb-2">
                            <span className="font-semibold">LN Sales Order ID:</span> {orderDetails.lnSalesOrderId}
                        </Typography>
                        <Typography className="mb-4">
                            <span className="font-semibold">Date:</span> {orderDetails.date}
                        </Typography>
                        <div className="flex justify-end mt-4">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </div>
                        {!redirectCancelled && (
                            <div className="mt-6 text-center">
                                <Typography className="text-sm text-gray-600">
                                    You're going to be redirected to the home page in {countdown} seconds...
                                </Typography>
                                <Typography
                                    className="text-sm text-blue-600 underline cursor-pointer mt-2"
                                    onClick={handleCancelRedirect}
                                >
                                    Cancel redirect
                                </Typography>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>

                <div>
                    <div className="wheel-image mt-4 relative overflow-hidden rounded-t-lg h-40">
                        <img className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src={wheelImage} />

                        <p className="contenedor-texto">Stock Clearance</p>
                        <p className="description-card">30% off on stock clearance</p>
                    </div>

                    <div className="bg-white px-4 py-4">
                        <p className="text-lg font-semibold mb-2">Thank you for your interest in shopping with TRC.</p>
                        <p>Here are some tips to help ensure a smooth ordering experience and to help set expectations.</p>

                        <hr className="my-6 border-gray-300 border-1" />

                        <div className="flex items-center space-x-2 mb-4">
                            <p className='text-xs'>If you are ordering certain chemicals, be aware there may be limits on how we can ship your items that will change your orders shipping time.</p>
                        </div>

                        <div className="flex items-center space-x-2 mb-4">
                            <p className='text-xs'>The same goes for customers not in the continental United States. </p>
                        </div>
                        <div className="flex items-center space-x-2 mb-4">
                            <p className='text-xs'>Other individual states, such as California, may have additional restrictions on what or how we can ship.</p>
                        </div>

                        <hr className="my-6 border-gray-300 border-1" />

                        <p className='text-center mb-4'> Please review our full shipping
                            Terms and conditions found here to learn more about how we will fulfill your order.
                        </p>

                        <button className="w-full text-black font-semibold py-2 rounded border border-black cursor-pointer" onClick={() => { navigate('tos'); }}>Terms & Conditions</button>
                    </div>
                </div>
            </div>
        </div >
    );

}
