import React, { useState, useEffect, useContext, useRef } from 'react';
import { motion } from "framer-motion";
import { SavedIcon, CheckIcon, SaveIcon, NoDataIcon } from '../assets/icons/hiking';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { Badge } from 'antd';
import Backdrop from '@mui/material/Backdrop';
import { Breadcrumb } from 'antd';
import { SearchContext } from '../context/SearchContext';
import Sidebar from '../components/productSidebar';
import CircularProgress from '@mui/material/CircularProgress';
import { useCart } from '../context/CartContext';
import { Button } from '@mui/material';

export default function Products() {
    const { triggerCartUpdate } = useCart();
    const [products, setProducts] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    // const { searchTerm } = useContext(SearchContext);
    const { searchTerm, setProducts: setContextProducts } = useContext(SearchContext);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));
    const [cartNonces, setCartNonces] = useState([]);
    const [selectedCatalog, setSelectedCatalog] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [counts, setCounts] = useState({});

    useEffect(() => {
        fetchProducts();
    }, [queryParams, searchTerm, selectedCatalog, selectedCategories]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const abortControllerRef = useRef(null);
    const requestCounter = useRef(0);

    const fetchProducts = () => {
        const eid = localStorage.getItem("default");
        const nonce = localStorage.getItem("nonce");
        const type = "product";

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        const currentRequestId = ++requestCounter.current;
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const controller = new AbortController();
        const { signal } = controller;
        abortControllerRef.current = controller;

        setIsLoadingProducts(true);

        const catalogFilter = selectedCatalog;
        const categoryFilter = selectedCategories;


        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&search=" + searchTerm + "&nonce=" + nonce + "&filter=" + "&catalog=" + catalogFilter + "&category=" + categoryFilter;


        fetch(url, { signal, priority: 'high' })
            .then((response) => response.json())
            .then((data) => {
                if (currentRequestId === requestCounter.current) {
                    if (data.success) {
                        if (data.data.response === "NO" || !Array.isArray(data.data.response)) {
                            setProducts([]);
                            setContextProducts([]);
                            console.log('No products returned from API or response is "NO"');
                        } else {
                            const products = data.data.response;
                            setProducts(products);
                            setContextProducts(products);
                            console.log('Products fetched and set to context:', products.length);

                            const initialCounts = {};
                            products.forEach((product) => {
                                const id = product.id;
                                const quantity = product.product?.cart || 0;
                                initialCounts[id] = quantity;
                            });
                            setCounts(initialCounts);
                        }
                    } else {
                        setProducts([]);
                    }
                    setIsLoadingProducts(false);
                }
            })
            .catch(error => {
                if (error.name === "AbortError") {
                    console.log("Fetch aborted: ignoring data reset.");
                    return;
                }
                console.error("Error fetching data:", error);

                // ✅ Only update state if this is the latest request
                if (currentRequestId === requestCounter.current) {
                    setProducts([]);
                    setIsLoadingProducts(false);
                }
            });
    };


    const [quickAdd, setQuickAdd] = useState(false);

    useEffect(() => {
        const updateQuickAdd = () => {
            const value = localStorage.getItem("quick_add");
            setQuickAdd(value === "true");
        };

        updateQuickAdd();
        window.addEventListener("quick_add_changed", updateQuickAdd);
        return () => window.removeEventListener("quick_add_changed", updateQuickAdd);
    }, []);


    const [priceLookUpLoading, setPriceLookUpLoading] = useState(false);
    const [popupProductData, setPopupProductData] = useState(null);
    const [popupCount, setPopupCount] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);

    function addToNttCart(productData) {
        const { id, nonce, title, Description, image, sku, item_number, initialCount } = productData;

        const eid = localStorage.getItem("default");
        const a = localStorage.getItem("nonce");

        const existingCount = counts[id] ?? 0;
        const newCount = existingCount + 1;

        const payload = {
            eid,
            a,
            endpoint: 'v1/cart/update',
            quantities: [
                {
                    name: nonce,
                    value: newCount,
                },
            ],
        };


        setPriceLookUpLoading(true);

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setPriceLookUpLoading(false);
                if (data.success && data.response === 'OK') {
                    // 2) If this product’s nonce hasn’t been tracked yet, add it.
                    setCartNonces(prevNonces => {
                        if (!prevNonces.includes(nonce)) {
                            return [...prevNonces, nonce];
                        }
                        return prevNonces;
                    });

                    setProducts(prevProducts =>
                        prevProducts.map(product => {
                            if (product.product.nonce === nonce) {
                                return {
                                    ...product,
                                    product: {
                                        ...product.product,
                                        cart: newCount,
                                    },
                                };
                            }
                            return product;
                        })
                    );

                    setCounts(prev => ({
                        ...prev,
                        [id]: newCount,
                    }));

                    setPopupProductData({
                        id,
                        title,
                        Description,
                        image,
                        sku,
                        item_number,
                        initialCount: newCount,
                        nonce,
                    });
                    setPopupCount(newCount);
                    setModalOpen(true);
                    triggerCartUpdate();
                } else {
                    alert("No price found");
                    return null;
                }
            })

            .catch(() => {
                setPriceLookUpLoading(false);
                alert("Something went wrong");
                return null;
            });
    }

    function updateNttCart(nonce, quantity) {
        const eid = localStorage.getItem("default");
        const a = localStorage.getItem("nonce");

        const payload = {
            eid: eid,
            a: a,
            endpoint: 'v1/cart/update/quantity',
            quantities: [
                {
                    name: nonce,
                    value: quantity,
                },
            ],

        };

        setPriceLookUpLoading(true);

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setPriceLookUpLoading(false);
                if (data.success && data.response === "OK") {
                    setModalOpen(false);
                    triggerCartUpdate();

                    fetchProducts();
                } else {
                    alert("Can't update Product.");
                }
            })
            .catch(() => {
                setPriceLookUpLoading(false);
                alert("Something went wrong while updating the cart.");
            });
    }

    // Update quantity of cart.
    const updateCartQuantity = () => {

        const eid = localStorage.getItem("default");
        const nonce = localStorage.getItem("nonce");

        setPriceLookUpLoading(true);

        const updatedQuantities = Object.entries(counts)
            .filter(([productId, quantity]) => quantity > 0)
            .map(([productId, quantity]) => {
                const matchingProduct = products.find((p) => String(p.id) === String(productId));

                const productNonce = matchingProduct?.product?.nonce;
                return {
                    name: productNonce,
                    value: quantity
                };
            });

        const payload = {
            a: nonce,
            eid: eid,
            endpoint: "v1/cart/update/quantity",
            quantities: updatedQuantities
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                setPriceLookUpLoading(false);
                if (data.success && data.response === "OK") {
                    fetchProducts();
                    triggerCartUpdate();
                } else {
                    alert("We can't update your Cart at this moment.");
                    return null;
                }
            })
            .catch(error => {
                console.error('Error updating cart:', error);
            });
    };


    // QUICK ADD CONTROLS
    const handleIncrement = (id) => {
        setCounts(prev => ({
            ...prev,
            [id]: (prev[id] || 0) + 1
        }));
    };

    const handleDecrement = (id) => {
        setCounts(prev => ({
            ...prev,
            [id]: Math.max(0, (prev[id] || 0) - 1)
        }));
    };

    const handleChange = (id, value) => {
        const intValue = parseInt(value, 10);
        setCounts(prev => ({
            ...prev,
            [id]: (!isNaN(intValue) && intValue >= 0) ? intValue : ''
        }));
    };

    const handleBlur = (id) => {
        setCounts(prev => ({
            ...prev,
            [id]: prev[id] === '' ? 0 : prev[id]
        }));
    };


    //save product
    const handleProductSave = (product) => {
        const { nonce } = product;

        const eid = localStorage.getItem('default');
        const a = localStorage.getItem('nonce');

        setProducts((prevProducts) =>
            prevProducts.map((p) => {
                if (p.product.nonce === nonce) {
                    return {
                        ...p,
                        product: {
                            ...p.product,
                            saved: 1, // same pattern as cart: 1
                        },
                    };
                }
                return p;
            })
        );

        const payload = {
            eid: eid,
            endpoint: "v1/collection/add/items",
            a: a,
            products: [nonce]
        };

        // setPriceLookUpLoading(true);

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {
                    console.log("product saved")
                } else {
                    alert(data.error || "Failed to save the product");
                }
            })
            .catch((error) => {
                console.error("Save product error:", error);
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    };

    const productUnsave = (nonce) => {
        const eid = localStorage.getItem('default');
        const a = localStorage.getItem("nonce");


        setProducts((prevProducts) =>
            prevProducts.map((p) => {
                if (p.product.nonce === nonce) {
                    return {
                        ...p,
                        product: {
                            ...p.product,
                            saved: 0,
                        },
                    };
                }
                return p;
            })
        );

        const payload = {
            eid: eid,
            endpoint: "v1/collection/delete/items",
            products: [nonce],
            a: a
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === 1 && data.response && data.response === "OK") {
                    console.log("product unsaved")
                }
            })
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    };


    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    function AddToCartPopup({ product, onClose, count, increment, decrement, inputCounter, nonce, updateQuantity }) {
        const decodedTitle = decodeHtml(product.title);
        const decodedDescription = decodeHtml(product.Description);
        const popupRef = useRef(null);

        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };

        return (
            <div
                className="fixed z-10 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
                onClick={handleOutsideClick}
            >
                <div ref={popupRef} className="bg-white p-5 rounded-lg shadow-lg w-full max-w-4xl mx-4 md:mx-6 lg:mx-8">
                    <div className="py-4 flex items-center justify-center p-4 bg-green-100">
                        <CheckIcon className="w-6 h-6 md:w-8 md:h-8" />
                        <p className="font-bold text-green-600 ml-2 text-sm md:text-base lg:text-lg">Product successfully added to your cart!</p>
                    </div>

                    <div className="flex flex-col md:flex-row px-4">
                        <img
                            src={product.image}
                            className="mb-4 md:mb-0 md:mr-4 max-w-full md:max-w-xs h-auto"
                            alt={product.title}
                        />

                        <div className="flex-1">
                            <p className="text-lg font-semibold mb-2 text-center md:text-left">{decodedTitle}</p>
                            <p className="text-base mb-4 text-center md:text-left">{decodedDescription}</p>

                            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                <div className="flex items-center mb-4 md:mb-0">
                                    <button className="px-3 py-1 border rounded" onClick={decrement}>-</button>
                                    <input
                                        className="mx-2 px-3 py-1 border rounded text-center"
                                        style={{ width: '64px' }}
                                        type="text"
                                        value={count}
                                        onChange={inputCounter}
                                    />
                                    <button className="px-3 py-1 border rounded" onClick={increment}>+</button>

                                    {count !== product.initialCount && (
                                        <button
                                            className="ml-2 px-2 py-1 bg-yellow-500 text-white rounded text-xs md:text-sm"
                                            onClick={() => updateQuantity(nonce, count)}
                                        >
                                            Update
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row p-4">
                        <button className="py-2 px-4 font-semibold bg-black text-white rounded flex-1 mb-2 md:mb-0 md:mr-2" onClick={onClose}>Continue Shopping</button>
                        <button
                            className="py-2 px-4 font-semibold bg-red-500 text-white rounded hover:bg-red-700 flex-1"
                            onClick={() => (window.location.href = 'https://one.trc4r.dev/app/cart')}
                        >
                            View Cart & Checkout
                        </button>

                    </div>
                </div>
            </div>

        );
    }

    function handleButtonClick(nonce) {
        window.location.href = `/app/product?nonce=${nonce}`;
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ paddingTop: '120px' }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={priceLookUpLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="py-6">

                <div className=" py-2 pl-8">
                    <div>
                        <Breadcrumb>
                            <Breadcrumb.Item><a href="home">Home</a></Breadcrumb.Item>
                            <Breadcrumb.Item>Products</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <hr className="my-2 border-gray-200 border-1" />


                <div className="flex">

                    <Sidebar setSelectedCatalog={setSelectedCatalog} setSelectedCategories={setSelectedCategories} />

                    {isLoadingProducts ? (
                        <Grid container spacing={4}>
                            {Array.from(new Array(20)).map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                    <Box sx={{ width: '100%', padding: 2 }}>
                                        <Skeleton variant="rectangular" width="100%" height={300} />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Skeleton width="15%" />
                                                <Skeleton width="15%" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    ) : products.length > 0 ? (

                        <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-5 w-full">
                            {Array.isArray(products) && products.map((product) => {
                                const productImage = product.images && product.images.images_primary;
                                const productTitle = product.product && decodeHtml(product.product.title);
                                const productShortDescription = product.product && decodeHtml(product.product.short_description);
                                const productPrice = product.properties && product.properties.use_price;
                                const productSKU = product.identifiers && product.identifiers.sku;
                                const item_product = product.ln && product.ln.item_number;

                                const quantityInCart = product.product.cart;
                                const isInCart = quantityInCart > 0;
                                const isSaved = product.product.saved === 1;

                                return (
                                    <div key={product.id} className="w-[264px] text-center relative">
                                        {/* {isInCart && (
                                            <Badge.Ribbon text="Added To Cart" color="red" style={{ zIndex: 1, width: 'auto', height: 'auto' }} />
                                        )} */}
                                        {isInCart && (
                                            <Badge.Ribbon
                                                text={`Added To Cart (${quantityInCart})`}
                                                color="red"
                                                style={{ zIndex: 1, width: 'auto', height: 'auto' }}
                                            />
                                        )}

                                        <div className="blur-on-hover relative bg-white w-[264px] h-[307px] flex flex-col justify-center items-center mx-auto">
                                            <img src={productImage} alt={productTitle} className="max-w-[220px] h-auto" />

                                            <div className="absolute bottom-2 w-full px-2">
                                                <div className="border-t border-gray-300 flex justify-between items-center pt-2 relative">
                                                    <span className="text-sm font-bold px-1">{productSKU}</span>
                                                    <span className="text-sm font-bold px-1">{item_product}</span>
                                                    <div className="absolute top-0 bottom-0 left-1/2 w-px bg-gray-300 transform -translate-x-1/2"></div>
                                                </div>
                                            </div>
                                            {!quickAdd && (
                                                <button
                                                    className="add-to-cart-button font-semibold opacity-0 text-sm"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '40%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 2
                                                    }}
                                                    onClick={() => addToNttCart({
                                                        id: product.id,
                                                        nonce: product.product.nonce,
                                                        title: productTitle,
                                                        Description: productShortDescription,
                                                        image: productImage,
                                                        sku: productSKU,
                                                        item_number: product.ln ? product.ln.item_number : null,
                                                        custom_1: product.custom.custom_1,
                                                        custom_2: product.custom.custom_2,
                                                        custom_3: product.custom.custom_3,
                                                        custom_4: product.custom.custom_4,
                                                        initialCount: 1
                                                    })}
                                                >
                                                    Add to Cart
                                                </button>
                                            )}

                                            <button
                                                className="add-to-cart-button font-semibold opacity-0 text-sm"
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 2
                                                }}
                                                onClick={() => handleButtonClick(product.product.nonce)}
                                            >
                                                View Product
                                            </button>
                                        </div>
                                        <div style={{ textAlign: 'left', marginTop: '10px', width: '264px', margin: 'auto' }}>
                                            <span className="font-semibold text-sm block truncate">{productTitle}</span>
                                            <span className="font-medium text-sm block truncate">{productShortDescription}</span>

                                            <div className="flex justify-between items-center">

                                                {quickAdd ? (
                                                    <div className="counter">
                                                        <button onClick={() => handleDecrement(product.id)}>-</button>
                                                        <input
                                                            type="number"
                                                            value={counts[product.id] ?? 0}
                                                            onChange={(e) => handleChange(product.id, e.target.value)}
                                                            onBlur={() => handleBlur(product.id)}
                                                            min="0"
                                                        />
                                                        <button onClick={() => handleIncrement(product.id)}>+</button>
                                                    </div>
                                                ) : (
                                                    <span className="font-bold text-lg"></span>
                                                )}


                                                <Tooltip title={isSaved ? "Item saved" : "Save item"} arrow>
                                                    <div
                                                        className="cursor-pointer relative ml-auto"
                                                        onClick={() => {
                                                            if (isSaved) {
                                                                productUnsave(product.product.nonce)
                                                            } else {

                                                                handleProductSave({
                                                                    id: product.id,
                                                                    title: productTitle,
                                                                    description: productShortDescription,
                                                                    sku: productSKU,
                                                                    image: productImage,
                                                                    item_number: item_product,
                                                                    price: productPrice,
                                                                    nonce: product.product.nonce
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {isSaved ? <SavedIcon /> : <SaveIcon />}
                                                    </div>
                                                </Tooltip>
                                            </div>

                                        </div>

                                    </div>
                                );
                            })}
                            {modalOpen && popupProductData && (
                                <AddToCartPopup
                                    product={popupProductData}
                                    count={popupCount}
                                    nonce={popupProductData.nonce}
                                    onClose={() => setModalOpen(false)}
                                    increment={() => setPopupCount(popupCount + 1)}
                                    decrement={() => setPopupCount(Math.max(1, popupCount - 1))}
                                    inputCounter={(e) => {
                                        const value = parseInt(e.target.value, 10);
                                        if (!isNaN(value)) setPopupCount(value);
                                    }}
                                    updateQuantity={(id, count) => {
                                        const productNonce = popupProductData.nonce;
                                        updateNttCart(productNonce, count);
                                    }}
                                />
                            )}

                            {quickAdd && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        marginTop: 1.5,
                                        width: '200px',
                                        color: 'white',
                                        position: 'fixed',
                                        fontWeight: 600,
                                        bottom: 16,
                                        right: 16,
                                        zIndex: 1000,
                                        paddingX: 3,
                                        paddingY: 1.5,
                                        textTransform: 'none',
                                        boxShadow: 3,
                                        backgroundColor: 'black',
                                        '&:hover': {
                                            backgroundColor: '#333',
                                        },
                                    }}
                                    onClick={updateCartQuantity}
                                >
                                    Update Cart
                                </Button>
                            )}

                        </div>

                    ) : (
                        <div className="flex flex-col w-full items-center pt-14">
                            <div className="flex flex-col items-center">
                                <span className="text-xl font-semibold">No products.</span>
                            </div>
                            <NoDataIcon />
                        </div>
                    )}

                </div >
            </div >
        </motion.div >
    );

}

